import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-steril-pipe',
  templateUrl: './steril-pipe.component.html',
  styleUrls: ['./steril-pipe.component.scss']
})
export class SterilPipeComponent implements OnInit {
  idx: number = 1;
  @Output() idxEvent = new EventEmitter<number>();
  @Input() whichLang: string;

  altri = [
    { img: "../../../assets/NoorSterilPipe(40cm).jpg",
      nome: 'SANIFICAZIONE A LUCE ULTRAVIOLETTE UV-C ALTAMENTE EFFICIENTE PER AMBIENTI',
      descrizione: 'Dimensione piccola. (40 cm)',
      descrPrezzo: 'euro',
      cash: 450.00,
      qty: 1
   },
   { img: "../../../assets/NoorSterilPipe(75cm).jpg",
      nome: 'SANIFICAZIONE A LUCE ULTRAVIOLETTE UV-C ALTAMENTE EFFICIENTE PER AMBIENTI',
      descrizione: 'Dimensione media. (75 cm)',
      descrPrezzo: 'euro',
      cash: 750.00,
      qty: 1
   },
   { img: "../../../assets/NoorSterilPipe(100cm).jpg",
      nome: 'SANIFICAZIONE A LUCE ULTRAVIOLETTE UV-C ALTAMENTE EFFICIENTE PER AMBIENTI',
      descrizione: 'Dimensione grande. (100 cm)',
      descrPrezzo: 'euro',
      cash: 1000.00,
      qty: 1
   },
   { img: "../../../assets/NoorSterilPipe(150cm).jpg",
      nome: 'SANIFICAZIONE A LUCE ULTRAVIOLETTE UV-C ALTAMENTE EFFICIENTE PER AMBIENTI',
      descrizione: 'Dimensione enorme. (150 cm)',
      descrPrezzo: 'euro',
      cash: 1450.00,
      qty: 1
   },
  ];

  altren = [
    { img: "../../../assets/NoorSterilPipe(40cm).jpg",
      nome: 'HOCHEFFIZIENTE UV-C-ULTRAVIOLETTLICHT-DESINFEKTION FÜR UMGEBUNGEN',
      descrizione: 'Kleine Grösse. (40 cm)',
      descrPrezzo: 'euro',
      cash: 450.00,
      qty: 1
   },
   { img: "../../../assets/NoorSterilPipe(75cm).jpg",
      nome: 'HOCHEFFIZIENTE UV-C-ULTRAVIOLETTLICHT-DESINFEKTION FÜR UMGEBUNGEN',
      descrizione: 'Durchschnittliche Grösse. (75 cm)',
      descrPrezzo: 'euro',
      cash: 750.00,
      qty: 1
   },
   { img: "../../../assets/NoorSterilPipe(100cm).jpg",
      nome: 'HOCHEFFIZIENTE UV-C-ULTRAVIOLETTLICHT-DESINFEKTION FÜR UMGEBUNGEN',
      descrizione: 'Grosse Grösse. (100 cm)',
      descrPrezzo: 'euro',
      cash: 1000.00,
      qty: 1
   },
   { img: "../../../assets/NoorSterilPipe(150cm).jpg",
      nome: 'HOCHEFFIZIENTE UV-C-ULTRAVIOLETTLICHT-DESINFEKTION FÜR UMGEBUNGEN',
      descrizione: 'Riesige Größe. (150 cm)',
      descrPrezzo: 'euro',
      cash: 1450.00,
      qty: 1
   },
  ];

  altris = [
    { img: "../../../assets/NoorSterilPipe(40cm).jpg",
      nome: 'Highly effective UV-C ultraviolet light sanitization for rooms',
      descrizione: 'Small size. (40 cm)',
      descrPrezzo: 'euro',
      cash: 450.00,
      qty: 1
   },
   { img: "../../../assets/NoorSterilPipe(75cm).jpg",
      nome: 'Highly effective UV-C ultraviolet light sanitization for rooms',
      descrizione: 'Medium size. (75 cm)',
      descrPrezzo: 'euro',
      cash: 750.00,
      qty: 1
   },
   { img: "../../../assets/NoorSterilPipe(100cm).jpg",
      nome: 'Highly effective UV-C ultraviolet light sanitization for rooms',
      descrizione: 'Large size. (100 cm)',
      descrPrezzo: 'euro',
      cash: 1000.00,
      qty: 1
   },
   { img: "../../../assets/NoorSterilPipe(150cm).jpg",
      nome: 'Highly effective UV-C ultraviolet light sanitization for rooms',
      descrizione: 'Huge size. (150 cm)',
      descrPrezzo: 'euro',
      cash: 1450.00,
      qty: 1
   },
  ];

  constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }

 ngOnInit(): void {
 }

 onAddToCart(nm, im, pr) {
   const alimName = nm;
   const alimPrezzo = pr;
   const alimeImg = im;
   const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
   this.bService.addAliment(newAliment);
   //this.idx += 1;
   this.idxEvent.emit(this.idx);
 }

}
