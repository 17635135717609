<ngb-carousel #carousel [interval]="2500" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
  <ng-template ngbSlide>
    <div class="carousel-caption">
      <p [translate]="'feedback.a1'"></p>
      <!--<i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i>
        <i class="fas fa-star fa-2x"></i>-->
    </div>
    <div class="picsum-img-wrapper">
      <div class="row">
        <!--<div class="col-1"><i class="fas fa-user-circle fa-5x"></i></div>-->
        <div class="col-11 col-xs-8">
          <p [translate]="'feedback.r1_1'"></p>
          <p [translate]="'feedback.r1_2'"></p>
          <p [translate]="'feedback.r1_3'"></p>
          <p [translate]="'feedback.r1_4'"></p>
          <p [translate]="'feedback.r1_5'"></p>
        </div>
      </div>
    </div>
  </ng-template>


</ngb-carousel>
