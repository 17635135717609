import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Magliette } from '../magliette.model';

@Injectable()
export class MaglietteService {
  alimChanged = new Subject<Magliette[]>();
  private aliments: Magliette[] = [];

  setMagliette(aliments: Magliette[]) {
    this.aliments = aliments;
    this.alimChanged.next(this.aliments.slice());
  }
}
