import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-olio',
  templateUrl: './olio.component.html',
  styleUrls: ['./olio.component.scss']
})

export class OlioComponent implements OnInit {
  idx: number = 1;
  @Output() idxEvent = new EventEmitter<number>();
  @Input() whichLang: string;

  oli = [
    { img: "../../assets/OlioEVOBIOSelinonlattina3L.jpg",//"../../../assets/Oliod'olivanocellaraBiologico3LT.jpg",
	   nome: "Olio d'oliva nocellara Biologico 3 LT",
	   descrizione: "Lattina da 3 L.",
	   descrPrezzo: "euro",
	   cash: 90.00,
	   qty: 1
	  },
  ];

  olien = [
    { img: "../../assets/OlioEVOBIOSelinonlattina3L.jpg",//"../../../assets/Olio d'oliva nocellara Biologico 3 LT.jpg",
	   nome: 'Nocellara Bio-Olivenöl',
	   descrizione: '3 L Dose.',
	   descrPrezzo: 'euro',
	   cash: 90.00,
	   qty: 1
	  },
  ];

  olis = [
    { img: "../../assets/OlioEVOBIOSelinonlattina3L.jpg",//"../../../assets/Olio d'oliva nocellara Biologico 3 LT.jpg",
       nome: 'Nocellara Organic Olive Oil',
       descrizione: '3 L can.',
       descrPrezzo: 'euro',
       cash: 90.00,
       qty: 1
    },
  ];


  constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }


  ngOnInit() {
    this.idx = this.bService.getIdx();
  }

  ngDoCheck() {
    this.idx = this.bService.getIdx();
  }

  onAddToCart(nm, im, pr) {
    const alimName = nm;
    const alimPrezzo = pr;
    const alimeImg = im;
    const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
    this.bService.addAliment(newAliment);
    //this.idx += 1;
    this.idxEvent.emit(this.idx);
    this.bService.setIdx(1);
  }

}
