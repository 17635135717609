import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AlimentiComponent } from './alimenti/alimenti.component';
import { CantumComponent } from './cantum/cantum.component';
// import { ColophoniComponent } from './colophoni/colophoni.component';
import { HomeComponent } from './home/home.component';
import { OrdinareComponent } from './ordinare/ordinare.component';
// import { OtherComponent } from './other/other.component';
// import { ProfumiComponent } from './profumi/profumi.component';
// import { ShopNavComponent } from './shop-nav/shop-nav.component';
// import { SupplementsComponent } from './supplements/supplements.component';
import { ThanksComponent } from './thanks/thanks.component';
import { QrComponent } from './qr/qr.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  // { path: 'Foods', component: AlimentiComponent },
  { path: 'YourBasket', component: OrdinareComponent },
  { path: 'ThankYou', component: ThanksComponent },
  // { path: 'Shop', component: ShopNavComponent },
  // { path: 'Perfums', component: ProfumiComponent },
  // { path: 'Crypto', component: ColophoniComponent },
  // { path: 'Other', component: OtherComponent },
  // { path: 'Supplements', component: SupplementsComponent },
  { path: 'Cantum', component: CantumComponent},

  {path: 'QR', component: QrComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
