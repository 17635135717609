import { Component, Injectable, OnInit } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-alimenti',
  templateUrl: './alimenti.component.html',
  styleUrls: ['./alimenti.component.scss'],
  animations: [
    inOutAnimation,
  ]
})
@Injectable()
export class AlimentiComponent implements OnInit {
  idx: number;
  whichLang = '';
  isCollapsed1 = true;
  isCollapsed2 = true;
  isCollapsed3 = true;

  alimenti = [
    /*{ img: "../../assets/OlioEVOBIOSelinonlattina3L.jpg",//"../../../assets/Oliod'olivanocellaraBiologico3LT.jpg",
	   nome: "Olio d'oliva nocellara Biologico 3 LT",
	   descrizione: "Lattina da 3 L.",
	   descrPrezzo: "euro",
	   cash: 90.00,
	   qty: 1
	  },
    { img: "../../assets/FotoOlive.jpg",//"../../../assets/Oliveverdischiacciateconditebusta250gr.jpg",
      nome: "Olive verdi schiacciate condite",
      descrizione: "Busta da 250 gr",
      descrPrezzo: "euro",
      cash: 9.50,
      qty: 1
    },
    { img: "../../assets/FotoOlive.jpg",//"../../../assets/Olivenereinfornateinoliobustada250gr.jpg",
      nome: "Olive nere infornate in olio",
      descrizione: "Busta da 250 gr",
      descrPrezzo: "euro",
      cash: 9.00,
      qty: 1
    },*/
    { img: "../../assets/FotoOlive.jpg",//"../../assets/Pate(tapenade)oliveconditeda180gr.jpg",
      nome: "Paté (tapenade) olive condite",
      descrizione: "Da 180 gr",
      descrPrezzo: "euro",
      cash: 9.00,
      qty: 1
    },
    { img: "../../assets/FotoOlive.jpg",//"../../assets/Pate(tapenade)olivenereda180gr.jpg",
      nome: "Paté (tapenade) olive nere",
      descrizione: "Da 180 gr",
      descrPrezzo: "euro",
      cash: 8.50,
      qty: 1
    },
    { img: "../../assets/FotoOlive.jpg",//"../../assets/Pate(tapenade)oliveverdida180gr.jpg",
      nome: "Paté (tapenade) olive verdi",
      descrizione: "Da 180 gr",
      descrPrezzo: "euro",
      cash: 8.50,
      qty: 1
   },
  ];

  alimenten = [
    { img: "../../assets/OlioEVOBIOSelinonlattina3L.jpg",//"../../../assets/Olio d'oliva nocellara Biologico 3 LT.jpg",
	   nome: 'Nocellara Bio-Olivenöl',
	   descrizione: '3 L Dose.',
	   descrPrezzo: 'euro',
	   cash: 90.00,
	   qty: 1
	  },
    { img: "../../../assets/Foto Olive.jpg",
      nome: 'Gewürzte zerdrückte grüne Oliven',
      descrizione: '250 gr Beutel',
      descrPrezzo: 'euro',
      cash: 9.50,
      qty: 1
   },
   { img: "../../../assets/Foto Olive..jpg",
      nome: 'Schwarze Oliven in Öl gebacken',
      descrizione: '250 gr Beutel',
      descrPrezzo: 'euro',
      cash: 9.00,
      qty: 1
   },
   { img: "../../assets/FotoOlive.jpg",
    nome: 'Pastete (Tapenade) gewürzte Oliven',
    descrizione: '180 gr Beutel',
    descrPrezzo: 'euro',
    cash: 9.00,
    qty: 1
 },
 { img: "../../assets/FotoOlive.jpg",
    nome: 'Schwarze Olivenpastete (Tapenade)',
    descrizione: '180 gr Beutel',
    descrPrezzo: 'euro',
    cash: 8.50,
    qty: 1
 },
 { img: "../../assets/FotoOlive.jpg",
    nome: 'Pastete (Tapenade) grüne Oliven',
    descrizione: '180 gr Beutel',
    descrPrezzo: 'euro',
    cash: 8.50,
    qty: 1
 },
];
aliments = [
  { img: "../../assets/OlioEVOBIOSelinonlattina3L.jpg",//"../../../assets/Olio d'oliva nocellara Biologico 3 LT.jpg",
	   nome: 'Nocellara Organic Olive Oil',
	   descrizione: '3 L can.',
	   descrPrezzo: 'euro',
	   cash: 90.00,
	   qty: 1
	},
  { img: "../../assets/FotoOlive.jpg",
    nome: 'Seasoned crushed green olives',
    descrizione: '250 gr bag',
    descrPrezzo: 'euro',
    cash: 9.50,
    qty: 1
 },
 { img: "../../assets/FotoOlive.jpg",
    nome: 'Black olives baked in oil',
    descrizione: '250 gr bag',
    descrPrezzo: 'euro',
    cash: 9.00,
    qty: 1
 },
 { img: "../../assets/FotoOlive.jpg",
  nome: 'Pate (tapenade) seasoned olives',
  descrizione: 'From 180 gr',
  descrPrezzo: 'euro',
  cash: 9.00,
  qty: 1
},
{ img: "../../assets/FotoOlive.jpg",
  nome: 'Black olive pate (tapenade)',
  descrizione: 'From 180 gr',
  descrPrezzo: 'euro',
  cash: 8.50,
  qty: 1
},
{ img: "../../assets/FotoOlive.jpg",
  nome: 'Pate (tapenade) green olives',
  descrizione: 'From 180 gr',
  descrPrezzo: 'euro',
  cash: 8.50,
  qty: 1
},
];

  constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }

  //whichLang = this.translate.get(this.translate.currentLang);
  //whichLang: string = this.translation.lang;


  ngOnInit(): void {
    this.whichLang = this.translation.getLanguage();
    this.translation.langChanged
      .subscribe(
        (whichLang) => {
          this.whichLang = whichLang.pop();
        }
      );
    //console.log(this.whichLang[0]);
    this.idx = this.bService.getIdx();
  }

  ngDoCheck() {
    this.idx = this.bService.getIdx();
  }

  onAddToCart(nm, im, pr) {
    const alimName = nm;
    const alimPrezzo = pr;
    const alimeImg = im;
    const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
    this.bService.addAliment(newAliment);
    this.idx += 1;
    this.bService.setIdx(1);
  }


  receiveIdx($event) {
    this.idx = $event;
  }

  test(){
    console.log(this.whichLang);
  }
}
