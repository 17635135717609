import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

const BACKEND_URL = environment.apiUrl + '/fattureCustom/2/send_mail/';

@Injectable({ providedIn: 'root'})
export class MailService {
  dati = [];

  headers = new HttpHeaders({
    //'Content-Type': 'multipart/form-data',
    Authorization: 'Token 2e2e5ac12baf45ee14ed181d2057111e00db9a26'
  });

  constructor(private http: HttpClient, private router: Router) {}

  sendMail(ordinazione) {
    const email = ordinazione.mail;
    const nome = ordinazione.nome;
    const cognome = ordinazione.cognome;
    const indirizzo = ordinazione.indirizzo;
    const articolo = ordinazione.articolo;
    const quantita = ordinazione.quantita;
    const prezzo_u = ordinazione.prezzo;
    const city = ordinazione.city;
    const cap = ordinazione.cap;
    const telefono = ordinazione.telefono;
    const state = ordinazione.state;
    const taglia = ordinazione.taglia;
    const spedizione = ordinazione.spedizione;
    //const path = 'https://firebasestorage.googleapis.com/v0/b/personale-f2c82.appspot.com/o/150543309_2521067248199245_9016197292398977635_n.jpg?alt=media&token=a53a8b90-7288-4476-9893-95056fc3c080';
    //const saluti = 'Il tuo team DEE Natural';
    //const email_seller = 'info@alohawebdesign.rocks';
    //const phone_seller = '079 545 89 16';
    //const iban = 'CH94 0076 4174 3068 2200 3';
    //const beneficiario = 'Ecobioenergy SA, 6563 Mesocco';
    //const twint = false;
    //const paypal = true;
    //const paypal_seller = 'info@ecobioenergy.ch';
    this.dati = [email, nome, cognome, indirizzo, articolo, quantita, prezzo_u, city, cap, telefono, state, taglia, spedizione];
    this.http.post(BACKEND_URL, this.dati, {headers: this.headers})
    .subscribe(
      data => {
          console.log('POST Request is successful ', data);
      },
      error => {
          console.log('Error', error);
      }
    );
    console.log('Funziona');
  }
}
