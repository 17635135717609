<app-head titolo="navbar.link5"></app-head>
<body>

  <div class="row">
    <!--<h1 [translate]="'navbar.link5'"></h1>-->
    <span class="spacer"></span>
    <app-basket-button *ngIf="idx > 0"></app-basket-button>
  </div>

  <p>

    <button type="button" class="btn btn-outline-link btn-lg btn-block rounded-pill" (click)="isCollapsed2 = !isCollapsed2"
            [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample" id="cats">
          <p id="titolo">Steril Ball</p>
          <i class="fas fa-chevron-down" [hidden]="!isCollapsed2"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed2"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed2">
    <app-steril-ball (idxEvent)="receiveIdx($event)" [whichLang]="whichLang"></app-steril-ball>
  </div>
  <hr>

  <p>

    <button type="button" class="btn btn-outline-link btn-lg btn-block rounded-pill" (click)="isCollapsed1 = !isCollapsed1"
            [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample" id="cats">
          <p id="titolo">Steril Pipe</p>
          <i class="fas fa-chevron-down" [hidden]="!isCollapsed1"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed1"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed1">
    <app-steril-pipe (idxEvent)="receiveIdx($event)" [whichLang]="whichLang"></app-steril-pipe>
  </div>
  <hr>



</body>
