import {TranslateService} from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class TranslationService {
  langChanged = new EventEmitter();

  private lang:string[] = [];
  //language = 'de';
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('it');
  }


  useLanguage(language: string) {
    this.translate.use(language);
    this.lang.push(language);
    this.langChanged.emit(this.lang.slice());
  }
  getLanguage() {
    return this.lang.pop();
  }
}
