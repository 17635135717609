<body>
  <h1 [translate]="'shop-nav.title'"></h1>
  <div class="container-fluid">
    <!--<a type="button" class="btn btn-success btn-lg rounded-pill" routerLink="/Crypto" style="margin-left: 33%;" [translate]="'navbar.link1'"></a>-->
    <a type="button" class="btn btn-success btn-lg rounded-pill" routerLink="/Supplements" [translate]="'navbar.link2'"></a>
    <a type="button" class="btn btn-success btn-lg rounded-pill" routerLink="/Perfums" [translate]="'navbar.link3'"></a>
    <a type="button" class="btn btn-success btn-lg rounded-pill" routerLink="/Foods" [translate]="'navbar.link4'"></a>
    <a type="button" class="btn btn-success btn-lg rounded-pill" routerLink="/Other" [translate]="'navbar.link5'"></a>
  </div>
</body>
