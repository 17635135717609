import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { zoomHover } from '../shared/my-animation';
import { TranslationService } from '../shared/translation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    zoomHover
  ]
})
export class HomeComponent implements OnInit {

  state1 = 'begin';
  state2 = 'begin';
  state3 = 'begin';
  state4 = 'begin';
  //constructor(private translate: TranslateService) {
    //translate.setDefaultLang('en');
  //}
  constructor(private translation: TranslationService) {
  }

  ngOnInit(): void {
    //window.location.href = "https://firebasestorage.googleapis.com/v0/b/dee-natural-provvisorio.appspot.com/o/flyer_settembre%202023%20copia-1.pdf?alt=media&token=6041c78d-2b69-4c7a-af91-4c91b30e7b36";

}

  onAnimare1() {
    this.state1 === 'begin' ? this.state1 = 'end' : this.state1 = 'begin';
  }
  onAnimare2() {
    this.state2 === 'begin' ? this.state2 = 'end' : this.state2 = 'begin';
  }
  onAnimare3() {
    this.state3 === 'begin' ? this.state3 = 'end' : this.state3 = 'begin';
  }
  onAnimare4() {
    this.state4 === 'begin' ? this.state4 = 'end' : this.state4 = 'begin';
  }
}
