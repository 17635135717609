import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators, NgForm} from '@angular/forms';
import { Magliette } from '../magliette.model';
import { BasketService } from './Basket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackComponent } from '../snack/snack.component';
import { Router } from '@angular/router';
import { MailService } from './mail.service';


@Component({
  selector: 'app-ordinare',
  templateUrl: './ordinare.component.html',
  styleUrls: ['./ordinare.component.scss']
})
export class OrdinareComponent implements OnInit {
  @ViewChild('f', {static: false}) signupForm1: NgForm;
  @ViewChild('g', {static: false}) signupForm2: NgForm;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  selected = 'option2';
  index: number;
  index2: number;
  index3: number;
  shirts = [];
  submitted1 = false;
  submitted2 = false;

  ordinazione = {
    nome: '',
    cognome: '',
    indirizzo: '',
    articolo: [],
    quantita: [],
    prezzo: [],
    taglia: '1 doser',
    mail: '',
    city: '',
    cap: '',
    telefono: '',
    state: '',
    spedizione: ''
  };

  constructor(private bService: BasketService,
              private snackBar: MatSnackBar,
              private router: Router,
              private mailService: MailService) {}

  ngOnInit() {
    this.shirts = this.bService.getAliments();
    this.bService.basketChanged
      .subscribe(
        (shirts: Magliette[]) => {
          this.shirts = shirts;
        }
      );
    console.log(this.shirts[0]);
  }

  onUpdate(nome) {
    this.index = this.shirts.findIndex(x => x.nome === nome);
    this.shirts[this.index].prezzo = this.signupForm1.value.ordinaData1.quant * this.shirts[this.index].prezzo;
  }

  onOrdina1() {
    this.submitted1 = true;
    for (let i = 0; i < this.shirts.length; i++) {
      this.ordinazione.articolo[i] = this.shirts[i].nome;
      this.ordinazione.quantita[i] = this.shirts[i].qty;
      this.ordinazione.prezzo[i] = this.shirts[i].prezzo;
    }
    console.log(this.ordinazione);
  }

  onTaglia(nome) {
    this.index2 = this.shirts.findIndex(x => x.nome === nome);
    this.shirts[this.index2].size = this.signupForm1.value.ordinaData1.tag;
    console.log(this.shirts[this.index2].size);
  }

  onDelete(nome) {
    this.index3 = this.shirts.findIndex(x => x.nome === nome);
    this.shirts.splice(this.index3, 1);
  }


  onOrdina2() {
    this.submitted2 = true;


    this.ordinazione.nome = this.signupForm2.value.ordinaData2.nome;
    this.ordinazione.cognome = this.signupForm2.value.ordinaData2.cognome;
    this.ordinazione.mail = this.signupForm2.value.ordinaData2.mail;
    this.ordinazione.telefono = this.signupForm2.value.ordinaData2.telefono;
    this.ordinazione.indirizzo = this.signupForm2.value.ordinaData2.indirizzo;
    this.ordinazione.city = this.signupForm2.value.ordinaData2.city;
    this.ordinazione.cap = this.signupForm2.value.ordinaData2.cap;
    this.ordinazione.state = this.signupForm2.value.ordinaData2.state;

    this.ordinazione.spedizione = 'Cantum';


    this.mailService.sendMail(this.ordinazione);


    this.signupForm1.reset();
    this.signupForm2.reset();

    this.snackBar.openFromComponent(SnackComponent, {
      data: {
        message: 'Hello, snackbar!'
      },
      duration: 2000,
      panelClass: ['snackbar']
    });
    this.router.navigate(['/ThankYou']);

  }
}
