import { Component, Injectable, OnInit } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss'],
  animations: [
    inOutAnimation
  ]
})
@Injectable()
export class OtherComponent implements OnInit {
  idx = 0;
  whichLang = '';
  isCollapsed1 = true;
  isCollapsed2 = true;





  constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }

  //whichLang = this.translate.get(this.translate.currentLang);
  //whichLang: string = this.translation.lang;


  ngOnInit(): void {
    this.whichLang = this.translation.getLanguage();
    this.translation.langChanged
      .subscribe(
        (whichLang) => {
          this.whichLang = whichLang.pop();
        }
      );
    //console.log(this.whichLang[0]);
  }
  receiveIdx($event) {
    this.idx = $event;
  }

  onAddToCart(nm, im, pr) {
    const alimName = nm;
    const alimPrezzo = pr;
    const alimeImg = im;
    const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
    this.bService.addAliment(newAliment);
    this.idx += 1;
  }

}
