import { Component, Injectable, OnInit } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-cantum',
  templateUrl: './cantum.component.html',
  styleUrls: ['./cantum.component.scss'],
  animations: [
    inOutAnimation,
  ]
})
@Injectable()
export class CantumComponent implements OnInit {
  idx: number;
  whichLang = '';
  isCollapsed1 = true;
  isCollapsed2 = true;
  isCollapsed3 = true;

  constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }

  cantimg = '../../assets/242604292_194370262792207_2435237932706562466_n.jpg';
  cant_cash = 20;
  cantdescrPrezzo = 'CHF';

  cantdescrizioneIT = "Integratore 100% alimentare con estratto all'aglio rosso ricco di selenio";
  cantdescrizioneEN = '100% food supplement with red garlic extract rich in selenium';
  cantdescrizioneDE = '100% Nahrungsergänzungsmittel mit selenreichem Extrakt aus rotem Knoblauch';


  ngOnInit(): void {
    this.whichLang = this.translation.getLanguage();
    this.translation.langChanged
      .subscribe(
        (whichLang) => {
          this.whichLang = whichLang.pop();
        }
      );
    this.idx = this.bService.getIdx();
  }

  ngDoCheck() {
    this.idx = this.bService.getIdx();
  }

  onAddToCart(nm, im, pr) {
    const alimName = nm;
    const alimPrezzo = pr;
    const alimeImg = im;
    const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
    this.bService.addAliment(newAliment);
    this.idx += 1;
    this.bService.setIdx(1);
  }


  receiveIdx($event) {
    this.idx = $event;
  }

}
