import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      window.location.href = "https://firebasestorage.googleapis.com/v0/b/dee-natural-provvisorio.appspot.com/o/flyer_settembre%202023%20copia-1.pdf?alt=media&token=6041c78d-2b69-4c7a-af91-4c91b30e7b36";

  }

}
