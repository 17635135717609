
<body>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill-opacity="1" d="M0,256L40,261.3C80,267,160,277,240,250.7C320,224,400,160,480,160C560,160,640,224,720,245.3C800,267,880,245,960,218.7C1040,192,1120,160,1200,144C1280,128,1360,128,1400,128L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>
<div class="container-fluid">
  <h4 [translate]="'footer.text'"></h4>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <i class="fas fa-paper-plane"></i><a href="mailto:deenatural77@gmail.com">deenatural77@gmail.com</a>
    </div>
    <div class="col-sm-12 col-md-6" style="color: white;">
      <i class="fas fa-phone"></i>+41 79 545 89 16
    </div>
  </div>

  <hr>
  <div class="bottom" style="color: white;">
    Designed by <img src="../../assets/shaka_b.png" alt=""><a href="http://www.alohawebdesign.rocks" target="_blank" rel="noopener noreferrer">AlohaWebDesign</a>
  </div>
</div>
</body>
