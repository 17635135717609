<img src="../../../assets/fotosteeril.jpg" class="card-img-top" alt="..." loading="lazy">

<div class="container-fluid" [ngSwitch]="whichLang">
  <div *ngSwitchDefault>
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of altri">
        <!--<img src="{{ aliment.img }}" class="card-img-top" alt="...">-->
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.img, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngSwitchCase="'en'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of altrs">
        <!--<img src="{{ aliment.img }}" class="card-img-top" alt="...">-->
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.img, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'de'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of altren">
        <!--<img src="{{ aliment.img }}" class="card-img-top" alt="...">-->
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.img, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'it'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of altri">
        <!--<img src="{{ aliment.img }}" class="card-img-top" alt="...">-->
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.img, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'fr'">
    <h3>Va arriver bientôt</h3>
  </div>
