<body>
  <h1 [translate]="'ordinare.title'"></h1>

  <mat-horizontal-stepper #stepper [hidden]="submitted2">
    <ng-template matStepperIcon="edit">
    </ng-template>
    <mat-step label="Summary">
      <form [hidden]="submitted1" (ngSubmit)="onOrdina1()" #f="ngForm">
        <div class="form" id="iscr-data" ngModelGroup="ordinaData1" #ordinaData1="ngModelGroup">
        <!--<ng-template matStepLabel>Riepilogo</ng-template>-->
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col" [translate]="'ordinare.col1'"></th>
              <!--<th scope="col">Taglia</th>-->
              <th scope="col" [translate]="'ordinare.col2'"></th>
              <th scope="col" [translate]="'ordinare.col3'"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let shirt of shirts">
              <td>
                <div class="row">
                  <img src="{{ shirt.img }}" class="card-img-top" alt="{{ shirt.nome }}" style="height: 50px;width:50px;margin-left:7px">
                  {{ shirt.nome }}
                </div>
                <!--<br>
                <div class="dot1"></div>
                <div class="dot2"></div>-->
              <!--<td>
                <select class="form-control" (ngModel)="shirt.size" name="tag" required (change)="onTaglia(shirt.nome)">
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                </select>
              </td>-->
              <td>
                <div class="row">
                  <input type="text" class="form-control" id="quantita" placeholder="1" [(ngModel)]="shirt.qty" name="quant">
                  <button type="button" class="btn" (click)="onUpdate(shirt.nome)"><i class="fas fa-sync-alt fa-2x"></i></button>
                </div>
              </td>
              <td>CHF {{ shirt.prezzo }}</td>
              <td><button type="button" class="btn" (click)="onDelete(shirt.nome)"><i class="fas fa-trash fa-2x"></i></button></td>
            </tr>
          </tbody>
        </table>
        <div>
          <button type="submit" class="btn btn-lg btn-block rounded-pill" id="avdi" [disabled]="!f.valid" matStepperNext>Next</button>
        </div>
      </div>
      </form>
    </mat-step>
    <mat-step label="Personal data">
      <!--<ng-template matStepLabel>Dati personali</ng-template>-->
      <form class="p-5 mb-4 bg-light text-black" [hidden]="submitted2" (ngSubmit)="onOrdina2()" #g="ngForm">
        <div class="form" id="iscr-data" ngModelGroup="ordinaData2" #ordinaData2="ngModelGroup">
        <div class="form-row">
          <div class="form-group col-md-6 col-sm-12">
            <label for="inputEmail4" [translate]="'ordinare.nome'"></label>
            <input type="text" class="form-control" id="inputEmail4" placeholder="John" ngModel name="nome" required>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="inputPassword4" [translate]="'ordinare.cognome'"></label>
            <input type="text" class="form-control" id="inputPassword4" placeholder="Smith" ngModel name="cognome" required>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6 col-sm-12">
            <label for="inputEmail4">Email</label>
            <input type="email" class="form-control" id="inputEmail4" placeholder="test@mail.com" ngModel name="mail" required>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="inputPassword4" [translate]="'ordinare.telefono'"></label>
            <input type="text" class="form-control" id="inputPassword4" placeholder="070 000 00 00" ngModel name="telefono" required>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6 col-sm-12">
            <label for="inputAddress" [translate]="'ordinare.indirizzo'"></label>
            <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St" ngModel name="indirizzo" required>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="inputAddress" [translate]="'ordinare.citta'"></label>
            <input type="text" class="form-control" id="inputAddress" placeholder="Monte Carasso" ngModel name="city" required>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6 col-sm-12">
            <label for="inputCity" [translate]="'ordinare.nazione'"></label>
            <input type="text" class="form-control" id="inputCity" placeholder="Svizzera" ngModel name="state" required>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="inputZip">CAP</label>
            <input type="text" class="form-control" id="inputZip" placeholder="6513" ngModel name="cap" required>
          </div>
        </div>
        <button type="submit" class="btn btn-lg btn-block rounded-pill" id="ord" [disabled]="!g.valid" [translate]="'ordinare.ordina'"></button>
      </div>
      </form>
      <!--<button type="button" class="btn btn-lg btn-block rounded-pill" id="avdi" matStepperPrevious>Back</button>-->
    </mat-step>

  </mat-horizontal-stepper>
</body>
<!--<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#158496" fill-opacity="1" d="M0,256L40,261.3C80,267,160,277,240,250.7C320,224,400,160,480,160C560,160,640,224,720,245.3C800,267,880,245,960,218.7C1040,192,1120,160,1200,144C1280,128,1360,128,1400,128L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>-->
