import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';


@Component({
  selector: 'app-pate',
  templateUrl: './pate.component.html',
  styleUrls: ['./pate.component.scss']
})
export class PateComponent implements OnInit {
  idx: number = 1;
  @Output() idxEvent = new EventEmitter<number>();
  @Input() whichLang: string;

  pate = [
    { img: "../../assets/FotoOlive.jpg",//"../../assets/Pate(tapenade)oliveconditeda180gr.jpg",
      nome: "Paté (tapenade) olive condite",
      descrizione: "Da 180 gr",
      descrPrezzo: "euro",
      cash: 9.00,
      qty: 1
    },
    { img: "../../assets/FotoOlive.jpg",//"../../assets/Pate(tapenade)olivenereda180gr.jpg",
      nome: "Paté (tapenade) olive nere",
      descrizione: "Da 180 gr",
      descrPrezzo: "euro",
      cash: 8.50,
      qty: 1
    },
    { img: "../../assets/FotoOlive.jpg",//"../../assets/Pate(tapenade)oliveverdida180gr.jpg",
      nome: "Paté (tapenade) olive verdi",
      descrizione: "Da 180 gr",
      descrPrezzo: "euro",
      cash: 8.50,
      qty: 1
   },
  ];

  paten = [
    { img: "../../assets/FotoOlive.jpg",
    nome: 'Pastete (Tapenade) gewürzte Oliven',
    descrizione: '180 gr Beutel',
    descrPrezzo: 'euro',
    cash: 9.00,
    qty: 1
 },
 { img: "../../assets/FotoOlive.jpg",
    nome: 'Schwarze Olivenpastete (Tapenade)',
    descrizione: '180 gr Beutel',
    descrPrezzo: 'euro',
    cash: 8.50,
    qty: 1
 },
 { img: "../../assets/FotoOlive.jpg",
    nome: 'Pastete (Tapenade) grüne Oliven',
    descrizione: '180 gr Beutel',
    descrPrezzo: 'euro',
    cash: 8.50,
    qty: 1
 },
  ];

  pates = [
    { img: "../../assets/FotoOlive.jpg",
  nome: 'Pate (tapenade) seasoned olives',
  descrizione: 'From 180 gr',
  descrPrezzo: 'euro',
  cash: 9.00,
  qty: 1
},
{ img: "../../assets/FotoOlive.jpg",
  nome: 'Black olive pate (tapenade)',
  descrizione: 'From 180 gr',
  descrPrezzo: 'euro',
  cash: 8.50,
  qty: 1
},
{ img: "../../assets/FotoOlive.jpg",
  nome: 'Pate (tapenade) green olives',
  descrizione: 'From 180 gr',
  descrPrezzo: 'euro',
  cash: 8.50,
  qty: 1
},
  ];

  constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }


  ngOnInit() {
    this.idx = this.bService.getIdx();
  }

  ngDoCheck() {
    this.idx = this.bService.getIdx();
  }

  onAddToCart(nm, im, pr) {
    const alimName = nm;
    const alimPrezzo = pr;
    const alimeImg = im;
    const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
    this.bService.addAliment(newAliment);
    //this.idx += 1;
    this.idxEvent.emit(this.idx);
    this.bService.setIdx(1);
  }

}
