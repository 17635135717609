<app-head titolo="navbar.link2" id="testa"></app-head>
<body>

  <div class="row">
    <!--<h1 [translate]="'navbar.link2'"></h1>-->
    <span class="spacer"></span>
    <app-basket-button *ngIf="idx > 0"></app-basket-button>
  </div>


<div class="container-fluid" [ngSwitch]="whichLang">
  <div *ngSwitchDefault>
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of supplementi">
        <img src="{{ aliment.img }}" class="card-img-top" alt="{{ aliment.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.img, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngSwitchCase="'en'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of supplements">
        <img src="{{ aliment.img }}" class="card-img-top" alt="{{ aliment.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.img, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'de'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of supplementen">
        <img src="{{ aliment.img }}" class="card-img-top" alt="{{ aliment.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.img, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'it'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of supplementi">
        <img src="{{ aliment.img }}" class="card-img-top" alt="{{ aliment.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.img, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'fr'">
    <h3>Va arriver bientôt</h3>
  </div>
    <!--<div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of alimenten">
        <img src="{{ aliment.img }}" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.descrPrezzo, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>-->



</div>
</body>
