import { trigger, state, style, animate, transition } from '@angular/animations';

export const inOutAnimation = trigger(
  'inOutAnimation',
  [
    transition(
      ':enter',
      [
        style({ height: 0, opacity: 0 }),
        animate('0.5s ease-in-out',
                style({ height: 50, opacity: 1 }))
      ]
    )
  ]
);
export const btnState = trigger('btnState', [
  state('normal', style({'opacity': 1, transform: 'scale(1)'})),
  state('bigger', style({'opacity': 1, transform: 'scale(1)'})),
  transition('normal => *', [
    animate(200, style({
      'opacity': 0.5, transform: 'scale(2)'
    })),
    animate(200, style({
      opacity: 1, transform: 'scale(1)'
    }))
  ]),
]);

export const zoomHover = trigger('zoomHover', [
  state('begin', style({transform: 'scale(1)'})),
  state('end', style({transform: 'scale(1.05)'})),
  transition('begin => end', [
    animate(200)
  ]),
  transition('end => begin', [
    animate(200)
  ]),
]);

export const rotateChevronDown = trigger ('rotD', [
  state('1', style({transform: 'rotate(0deg)'})),
  state('2', style({transform: 'rotate(-90deg)'})),
  state('3', style({transform: 'rotate(-180deg'})),
  transition('1 => 2', [
    animate(100)
  ]),
  transition('2 => 3', [
    animate(100)
  ])
]);
export const rotateChevronUp = trigger ('rotUp', [
  state('down', style({transform: 'rotate(0deg)'})),
  state('up', style({transform: 'rotate(180deg'})),
  transition('down => up', [
    animate(100, style({transform: 'rotate(90deg)'})),
    animate(100, style({transform: 'rotate(180deg'}))
  ]),
  transition('up => down', [
    animate(100, style({transform: 'rotate(180deg)'})),
    animate(100, style({transform: 'rotate(90deg'}))
  ]),
]);
