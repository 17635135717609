import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-profumi-ambienti',
  templateUrl: './profumi-ambienti.component.html',
  styleUrls: ['./profumi-ambienti.component.scss']
})
export class ProfumiAmbientiComponent implements OnInit {
  idx: number = 1;
  @Output() idxEvent = new EventEmitter<number>();
  @Input() whichLang: string;

  ambienti = [
    { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12(1)-min.jpeg",
      nome: 'PROFUMO PER AMBIENTI',
      descrizione: 'Al gusto d’ UVA ROSSA E RIBES',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12-min.jpeg",
      nome: 'PROFUMO PER AMBIENTI',
      descrizione: 'Al gusto d’ ARANCIA E CANNELLA',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(2)-min.jpeg",
      nome: 'PROFUMO PER AMBIENTI',
      descrizione: 'Al gusto di BREZZA MARINA',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(1)-min.jpeg",
      nome: 'PROFUMO PER AMBIENTI',
      descrizione: 'Al gusto di VANIGLIA',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13-min.jpeg",
      nome: 'PROFUMO PER AMBIENTI',
      descrizione: 'Al gusto di AGRUMI E GELSOMINO',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 9.90,
      qty: 1
   },

  ];

  ambienten = [
    { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12(1)-min.jpeg",
      nome: 'Raumduft',
      descrizione: 'RED GRAPE AND RIBES aromatisiert',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12-min.jpeg",
      nome: 'Raumduft',
      descrizione: 'Mit Orangen- und Zimtgeschmack',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(2)-min.jpeg",
      nome: 'Raumduft',
      descrizione: 'Mit dem Geschmack von BREZZA MARINA',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(1)-min.jpeg",
      nome: 'Raumduft',
      descrizione: 'VANILLA aromatisiert',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13-min.jpeg",
      nome: 'Raumduft',
      descrizione: 'Aromatisiert mit KARROTEN UND JASMIN',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 9.90,
      qty: 1
   },

  ];

  ambients = [
    { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12(1)-min.jpeg",
      nome: 'ROOM PERFUME',
      descrizione: 'RED GRAPE AND RIBES flavoured',
      descrPrezzo: '100 ml container: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12-min.jpeg",
      nome: 'ROOM PERFUME',
      descrizione: 'Orange and cinnamon flavoured',
      descrPrezzo: '100 ml container: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(2)-min.jpeg",
      nome: 'ROOM PERFUME',
      descrizione: 'With the taste of BREZZA MARINA',
      descrPrezzo: '100 ml container: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(1)-min.jpeg",
      nome: 'ROOM PERFUME',
      descrizione: 'VANILLA flavoured',
      descrPrezzo: '100 ml container: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13-min.jpeg",
      nome: 'ROOM PERFUME',
      descrizione: 'Flavoured with CARROTS AND JASMINE',
      descrPrezzo: '100 ml container: euro',
      cash: 9.90,
      qty: 1
   },

  ];


  constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }

  ngOnInit() {
    this.idx = this.bService.getIdx();
  }

  ngDoCheck() {
    this.idx = this.bService.getIdx();
  }



  onAddToCart(nm, im, pr) {
    const alimName = nm;
    const alimPrezzo = pr;
    const alimeImg = im;
    const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
    this.bService.addAliment(newAliment);
    //this.idx += 1;
    this.idxEvent.emit(this.idx);
    this.bService.setIdx(1);
  }
}
