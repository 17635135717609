/*import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }*/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DropdownDirective } from './shared/dropdown.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';


// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CartButtonComponent } from './cart-button/cart-button.component';
import { AlimentiComponent } from './alimenti/alimenti.component';
import { ThanksComponent } from './thanks/thanks.component';
import { OrdinareComponent } from './ordinare/ordinare.component';
import { SnackComponent } from './snack/snack.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BasketService } from './ordinare/Basket.service';
import { MaglietteService } from './ordinare/magliette.service';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { ShopNavComponent } from './shop-nav/shop-nav.component';
import { ProfumiComponent } from './profumi/profumi.component';
import { MailService } from './ordinare/mail.service';
import { ColophoniComponent } from './colophoni/colophoni.component';
import { OtherComponent } from './other/other.component';
import { SupplementsComponent } from './supplements/supplements.component';
import { LayoutModule } from '@angular/cdk/layout';
import { BasketButtonComponent } from './basket-button/basket-button.component';
import { OlioComponent } from './olio/olio.component';
import { OliveComponent } from './olive/olive.component';
import { PateComponent } from './pate/pate.component';
import { ProfumiAmbientiComponent } from './profumi-ambienti/profumi-ambienti.component';
import { ProfumiNormaliComponent } from './profumi-normali/profumi-normali.component';
import { DiffusoriComponent } from './diffusori/diffusori.component';
import { SterilPipeComponent } from './steril-pipe/steril-pipe.component';
import { SterilBallComponent } from './steril-ball/steril-ball.component';
import { HeadComponent } from './head/head.component';
import { CantumComponent } from './cantum/cantum.component';
import { RecensioniComponent } from './recensioni/recensioni.component';
import { QrComponent } from './qr/qr.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    DropdownDirective,
    CartButtonComponent,
    AlimentiComponent,
    ThanksComponent,
    OrdinareComponent,
    SnackComponent,
    FooterComponent,
    ShopNavComponent,
    ProfumiComponent,
    ColophoniComponent,
    OtherComponent,
    SupplementsComponent,
    BasketButtonComponent,
    OlioComponent,
    OliveComponent,
    PateComponent,
    ProfumiAmbientiComponent,
    ProfumiNormaliComponent,
    DiffusoriComponent,
    SterilPipeComponent,
    SterilBallComponent,
    HeadComponent,
    CantumComponent,
    RecensioniComponent,
    QrComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    NgbModule,
    MatSnackBarModule,
    MatStepperModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    LayoutModule
  ],
  providers: [BasketService, MaglietteService, MailService],
  bootstrap: [AppComponent],
  entryComponents: [SnackComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
