import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-diffusori',
  templateUrl: './diffusori.component.html',
  styleUrls: ['./diffusori.component.scss']
})
export class DiffusoriComponent implements OnInit {
  idx: number = 1;
  @Output() idxEvent = new EventEmitter<number>();
  @Input() whichLang: string;

  diffusori = [
    { img: "../../../assets/o'xygendiffusore.jpg",
	   nome: "Diffusore o'xygen umidificatore a ultrasuoni",
	   descrizione: "Può essere accompagnata da oli essenziali. Umidificatore: Utilizzato solo con acqua, il 	sistema umidifica gradevolmente l’aria ambiente e contribuisce a migliorare il comfort delle vie 	respiratorie. Diffusore di oli essenziali: Versare qualche goccia di olio essenziale nel serbatoio dell’acqua per una diffusione aromatica",
	   descrPrezzo: 'euro',
	   cash: 49.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoredivaporeinalatore&hammamfacciale.jpg",
	   nome: 'Diffusore di vapore inalatore & hammam facciale',
	   descrizione: 'Vero e proprio prodotto per il benessere e per un trattamento a domicilio, il Diffusore di vapore Puressentiel® rilascia in pochi istanti vapore acqueo per un utilizzo 2 in 1: Inalatore e Hammam Facciale',
	   descrPrezzo: 'euro',
	   cash: 59.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoreultrasonicoapresaclip&diffuse.jpg",
	   nome: 'Diffusore ultrasonico a presa clip&diffuse',
	   descrizione: 'Diffondi, respira…e approfitta a pieno dei benefici degli oli essenziali! è un modello 	depositato esclusivo Puressentiel® Può essere usato quotidianamente in totale tranquillità e si integra perfettamente negli spazi in cui vivi (casa, ufficio,casa di vacanza)',
	   descrPrezzo: 'euro',
	   cash: 29.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusorespoutnik.jpg",
	   nome: 'Diffusore umidificatore a ultrasuoni spoutnik',
	   descrizione: "Può essere accompagnata o meno da oli essenziali. -Diffusore di oli essenziali: 	Aggiungere alcune gocce di oli essenziali nel serbatoio dell'acqua per una diffusione aromatica dalle 	svariate proprietà a seconda dell'olio o degli oli essenziali selezionati. Umidificatore: utilizzato con 	acqua, senza aggiunta di oli essenziali, il sistema umidifica piacevolmente l'aria dell'ambiente",
	   descrPrezzo: 'euro',
	   cash: 49.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoredioliessenzialiacaloredolce.jpg",
	   nome: 'Diffusore di oli essenziali a calore dolce',
	   descrizione: "Il diffusore a calore dolce per oli essenziali Puressentiel® è semplice da usare e consente di 	cambiare regolarmente l'olio con pochi facili gesti di manutenzione. Il suo design si adatta con eleganza a 	tutti gli ambienti di vita e di lavoro (casa, ufficio, hotel, studio medico, salone di bellezza, sala d’aspetto, palestra...)",
	   descrPrezzo: 'euro',
	   cash: 39.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoreelettricoapresaperoliessenziali.jpg",
	   nome: 'Diffusore elettrico a presa girevole',
	   descrizione: "Tutto il benessere dell'aromaterapia è sempre con voi. Ideale per un uso quotidiano 	totalmente sicuro grazie al coperchio di sicurezza, si integra perfettamente in tutti gli ambienti di vita e di lavoro (casa, ufficio, hotel, studi medici, saloni di bellezza, sale d'attesa, palestre...)",
	   descrPrezzo: 'euro',
	   cash: 29.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoreumidificatoreovoid.jpg",
	   nome: 'Diffusore umidificatore ovoid',
	   descrizione: "Questo sistema umidifica in maniera piacevole l’aria dell’ambiente e migliora il comfort delle vie respiratorie. La diffusione di oli essenziali offre un vero e proprio momento di 	relax e benessere, 	accompagnato da una delicata luce d’atmosfera dai colori cangianti o senza alcuna luce, a seconda delle vostre preferenze",
	   descrPrezzo: 'euro',
	   cash: 39.90,
	   qty: 1
	},

  ];

  diffusors = [
    { img: "../../../assets/o'xygendiffusore.jpg",
	   nome: "Diffuser o'xygen ultrasonic humidifier",
	   descrizione: 'It can be accompanied by essential oils. Humidifier: Used only with water, the system 	pleasantly humidifies the ambient air and helps improve the comfort of the respiratory tract. Essential oil diffuser: Pour a few drops of essential oil into the water tank for an aromatic diffusion',
	   descrPrezzo: 'euro',
	   cash: 49.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoredivaporeinalatore&hammamfacciale.jpg",
	   nome: 'Inhaler Steam Diffuser & Facial Hammam',
	   descrizione: 'A real product for well-being and for a home treatment, the Puressentiel® steam diffuser releases water vapor in a few moments for a 2-in-1 use: Inhaler and Facial Hammam',
	   descrPrezzo: 'euro',
	   cash: 59.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoreultrasonicoapresaclip&diffuse.jpg",
	   nome: 'Clip & diffuse grip ultrasonic diffuser',
	   descrizione: 'Diffuse, breathe ... and take full advantage of the benefits of essential oils! is an exclusive Puressentiel® registered model It can be used daily in total tranquility and integrates perfectly into the spaces in which you live (home, office, holiday home)',
	   descrPrezzo: 'euro',
	   cash: 29.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusorespoutnik.jpg",
	   nome: 'Spoutnik ultrasonic humidifier diffuser',
	   descrizione: 'It may or may not be accompanied by essential oils. - Essential oil diffuser: Add a few drops 	of essential oils to the water tank for an aromatic diffusion with various properties depending on the oil 	or essential oils selected. Humidifier: used with water, without adding essential oils, the system pleasantly humidifies the ambient air',
	   descrPrezzo: 'euro',
	   cash: 49.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoredioliessenzialiacaloredolce.jpg",
	   nome: 'Diffuser of essential oils with gentle heat',
	   descrizione: "The Puressentiel® Gentle Heat Diffuser for Essential Oils is simple to use and allows for 	regular oil changes with a few easy maintenance steps. Its design elegantly adapts to all living and 	working environments (home, office, hotel, doctor's office, beauty salon, waiting room, gym ...)",
	   descrPrezzo: 'euro',
	   cash: 39.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoreelettricoapresaperoliessenziali.jpg",
	   nome: 'Swivel socket electric diffuser',
	   descrizione: "All the well-being of aromatherapy is always with you. Ideal for a totally safe daily use thanks to the safety cover, it integrates perfectly into all living and working environments (home, office, 	hotel, doctor's offices, beauty salons, waiting rooms, gyms ...)",
	   descrPrezzo: 'euro',
	   cash: 29.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoreumidificatoreovoid.jpg",
	   nome: 'Ovoid humidifier diffuser',
	   descrizione: 'This system humidifies the ambient air in a pleasant way and improves the comfort of the respiratory tract. The diffusion of essential oils offers a real moment of relaxation and well-being, accompanied by a delicate mood light with changing colors or without any light, depending on your preferences',
	   descrPrezzo: 'euro',
	   cash: 39.90,
	   qty: 1
	},

  ];

  diffusoren = [
    { img: "../../../assets/o'xygendiffusore.jpg",
	   nome: "O'xygen Diffusor-Ultraschall-Luftbefeuchter",
	   descrizione: 'Kann durch ätherische Öle begleitet werden. Luftbefeuchter: Nur mit Wasser betrieben, 	befeuchtet das System die Umgebungsluft auf angenehme Weise und hilft, den Atemkomfort zu verbessern. Diffusor für ätherische Öle: Geben Sie einige Tropfen ätherisches Öl in den Wassertank für 	eine aromatische Diffusion',
	   descrPrezzo: 'euro',
	   cash: 49.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoredivaporeinalatore&hammamfacciale.jpg",
	   nome: 'Dampf-Diffusor-Inhalator & Gesichts-Hammam',
	   descrizione: 'Der Puressentiel® Dampfdiffusor ist ein echtes Produkt für das Wohlbefinden und die 	Behandlung zu Hause. Er setzt in wenigen Augenblicken Wasserdampf frei für eine 2 in 1 Anwendung: Inhalator und Gesichts-Hammam',
	   descrPrezzo: 'euro',
	   cash: 59.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoreultrasonicoapresaclip&diffuse.jpg",
	   nome: 'Clip&diffuse-Ultraschall-Lautsprecher',
	   descrizione: 'Diffuse, atmen ... und nutzen Sie die Vorteile der ätherischen Öle! ist ein exklusives registriertes Modell Puressentiel ® Es kann täglich in völliger Ruhe verwendet werden und integriert sich perfekt in die Räume, in denen Sie leben (Haus, Büro, Ferienhaus)',
	   descrPrezzo: 'euro',
	   cash: 29.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusorespoutnik.jpg",
	   nome: 'Spoutnik-Ultraschall-Luftbefeuchter-Diffusor',
	   descrizione: 'Kann von ätherischen Ölen begleitet sein, muss es aber nicht. -Essential Oil Diffuser: Geben Sie ein paar Tropfen ätherische Öle in den Wassertank für eine aromatische Diffusion mit einer Vielzahl von Eigenschaften, je nach dem gewählten ätherischen Öl(en). Luftbefeuchter: In Verbindung mit Wasser, ohne Zusatz von ätherischen Ölen, befeuchtet das System die Raumluft auf angenehme Weise',
	   descrPrezzo: 'euro',
	   cash: 49.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoredioliessenzialiacaloredolce.jpg",
	   nome: 'Diffusor für ätherische Öle mit sanfter Wärme',
	   descrizione: 'Der Puressentiel® Sanftwärme-Diffusor für ätherische Öle ist einfach zu bedienen und ermöglicht einen regelmäßigen Ölwechsel mit wenigen Handgriffen. Sein Design passt sich elegant an alle Wohn- und Arbeitsumgebungen an (Zuhause, Büro, Hotel, Arztpraxis, Schönheitssalon, Wartezimmer, Fitnessstudio...)',
	   descrPrezzo: 'euro',
	   cash: 39.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoreelettricoapresaperoliessenziali.jpg",
	   nome: 'Elektrischer Diffusor mit Drehgriff',
	   descrizione: 'Das ganze Wohlbefinden der Aromatherapie ist immer bei Ihnen. Ideal für den absolut sicheren täglichen Gebrauch dank seines Sicherheitsdeckels, integriert er sich perfekt in alle Wohn- und Arbeitsumgebungen (zu Hause, im Büro, im Hotel, in der Arztpraxis, im Schönheitssalon, in Wartezimmern, in Fitnessstudios usw.)',
	   descrPrezzo: 'euro',
	   cash: 29.90,
	   qty: 1
	},
	{ img: "../../../assets/diffusoreumidificatoreovoid.jpg",
	   nome: 'Eiförmiger Luftbefeuchter-Diffusor',
	   descrizione: 'Dieses System befeuchtet die Luft im Raum angenehm und verbessert den Komfort der 	Atemwege. Die Diffusion von ätherischen Ölen bietet einen wahren Moment der Entspannung und des 	Wohlbefindens, begleitet von einem zarten Stimmungslicht mit wechselnden Farben oder ganz ohne 	Licht, je nach Ihren Vorlieben',
	   descrPrezzo: 'euro',
	   cash: 39.90,
	   qty: 1
	},

  ];

  constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }

  ngOnInit() {
    this.idx = this.bService.getIdx();
  }

  ngDoCheck() {
    this.idx = this.bService.getIdx();
  }


  onAddToCart(nm, im, pr) {
    const alimName = nm;
    const alimPrezzo = pr;
    const alimeImg = im;
    const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
    this.bService.addAliment(newAliment);
    //this.idx += 1;
    this.idxEvent.emit(this.idx);
    this.bService.setIdx(1);
  }

}
