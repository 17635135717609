<app-head titolo="Cantum"></app-head>
<body>

  <!---->
  <div class="row">
    <!--<h1 [translate]="'navbar.link4'"></h1>-->
    <span class="spacer"></span>
    <app-basket-button *ngIf="idx > 0"></app-basket-button>
  </div>

  <div class="container-fluid" id="recensioni">
    <app-recensioni></app-recensioni>
  </div>

  <div class="row" id="shop">
    <div class="col-12">
      <div class="container-fluid" [ngSwitch]="whichLang">
        <div *ngSwitchDefault>
          <div class="row justify-content-center">
            <div class="card">
              <img src="../../assets/242604292_194370262792207_2435237932706562466_n.jpg" class="card-img-top" alt="Cantum Swiss" loading="lazy">
              <div class="card-body">
                <h5 class="card-title">Cantum Swiss</h5>
                <p class="card-text">{{ cantdescrizioneIT }}</p>
                <div class="card-footer">
                  <small class="text-muted">{{ cant_cash }} {{ cantdescrPrezzo }}</small>
                  <hr>
                  <app-cart-button (click)="onAddToCart('Cantum Swiss', cantimg, cant_cash)"></app-cart-button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div *ngSwitchCase="'en'">
          <div class="row justify-content-center">
            <div class="card">
              <img src="../../assets/242604292_194370262792207_2435237932706562466_n.jpg" class="card-img-top" alt="Cantum Swiss" loading="lazy">
              <div class="card-body">
                <h5 class="card-title">Cantum Swiss</h5>
                <p class="card-text">{{ cantdescrizioneEN }}</p>
                <div class="card-footer">
                  <small class="text-muted">{{ cant_cash }} {{ cantdescrPrezzo }}</small>
                  <hr>
                  <app-cart-button (click)="onAddToCart('Cantum Swiss', cantimg, cant_cash)"></app-cart-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'de'">
          <div class="row justify-content-center">
            <div class="card">
              <img src="../../assets/242604292_194370262792207_2435237932706562466_n.jpg" class="card-img-top" alt="Cantum Swiss" loading="lazy">
              <div class="card-body">
                <h5 class="card-title">Cantum Swiss</h5>
                <p class="card-text">{{ cantdescrizioneDE }}</p>
                <div class="card-footer">
                  <small class="text-muted">{{ cant_cash }} {{ cantdescrPrezzo }}</small>
                  <hr>
                  <app-cart-button (click)="onAddToCart('Cantum Swiss', cantimg, cant_cash)"></app-cart-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'it'">
          <div class="row justify-content-center">
            <div class="card">
              <img src="../../assets/242604292_194370262792207_2435237932706562466_n.jpg" class="card-img-top" alt="Cantum Swiss" loading="lazy">
              <div class="card-body">
                <h5 class="card-title">Cantum Swiss</h5>
                <p class="card-text">{{ cantdescrizioneIT }}</p>
                <div class="card-footer">
                  <small class="text-muted">{{ cant_cash }} {{ cantdescrPrezzo }}</small>
                  <hr>
                  <app-cart-button (click)="onAddToCart('Cantum Swiss',cantimg, cant_cash)"></app-cart-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'fr'">
          <h3>Va arriver bientôt</h3>
        </div>
      </div>
    </div>
  </div>

</body>
