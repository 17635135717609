import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-olive',
  templateUrl: './olive.component.html',
  styleUrls: ['./olive.component.scss']
})
export class OliveComponent implements OnInit {
  idx: number = 1;
  @Output() idxEvent = new EventEmitter<number>();
  @Input() whichLang: string;

  olive = [
    { img: "../../../assets/Oliveverdischiacciateconditebusta250gr.jpg", //"../../assets/FotoOlive.jpg",//"",
      nome: "Olive verdi schiacciate condite",
      descrizione: "Busta da 250 gr",
      descrPrezzo: "euro",
      cash: 9.50,
      qty: 1
    },
    /*{ img: "../../assets/FotoOlive.jpg",//"../../../assets/Olivenereinfornateinoliobustada250gr.jpg",
      nome: "Olive nere infornate in olio",
      descrizione: "Busta da 250 gr",
      descrPrezzo: "euro",
      cash: 9.00,
      qty: 1
    },*/
  ];

  oliven = [
    { img: "../../../assets/Oliveverdischiacciateconditebusta250gr.jpg",//"../../../assets/Foto Olive.jpg",
      nome: 'Gewürzte zerdrückte grüne Oliven',
      descrizione: '250 gr Beutel',
      descrPrezzo: 'euro',
      cash: 9.50,
      qty: 1
   },
   /*{ img: "../../../assets/Foto Olive..jpg",
      nome: 'Schwarze Oliven in Öl gebacken',
      descrizione: '250 gr Beutel',
      descrPrezzo: 'euro',
      cash: 9.00,
      qty: 1
   },*/
  ];

  olives = [
    { img: "../../../assets/Oliveverdischiacciateconditebusta250gr.jpg",//"../../assets/FotoOlive.jpg",
    nome: 'Seasoned crushed green olives',
    descrizione: '250 gr bag',
    descrPrezzo: 'euro',
    cash: 9.50,
    qty: 1
 },
 /*{ img: "../../assets/FotoOlive.jpg",
    nome: 'Black olives baked in oil',
    descrizione: '250 gr bag',
    descrPrezzo: 'euro',
    cash: 9.00,
    qty: 1
 },*/
  ];

  constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }


  ngOnInit() {
    this.idx = this.bService.getIdx();
  }

  ngDoCheck() {
    this.idx = this.bService.getIdx();
  }

  onAddToCart(nm, im, pr) {
    const alimName = nm;
    const alimPrezzo = pr;
    const alimeImg = im;
    const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
    this.bService.addAliment(newAliment);
    //this.idx += 1;
    this.idxEvent.emit(this.idx);
    this.bService.setIdx(1);
  }

}
