import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate, sequence } from '@angular/animations';
import { btnState } from '../shared/my-animation';

@Component({
  selector: 'app-cart-button',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.scss'],
  animations: [
    btnState
  ]
})
export class CartButtonComponent implements OnInit {

  state = 'normal';
  constructor() { }

  ngOnInit() {
  }

  onAddcart() {
    this.state === 'normal' ? this.state = 'bigger' : this.state = 'normal';
  }

}
