
<body>
  <app-head titolo="home.tit"></app-head>
  <a type="button" class="btn btn-success btn-lg rounded-pill animate__animated animate__jello animate__infinite	infinite" routerLink="/Cantum">To the Shop</a>


  <div class="container-fluid">
    <video width="100%" height="440" controls autoplay [muted]="'muted'">

      <source src="https://video.paginegialle.it/video/01/H07AVWEE/S42623290/VSP/VIDEOE.mp4" type="video/mp4">
    </video>
  </div>

  <!--<div class="row">
    <div class="col-xl-6 col-sm-12">
      <img src="../../assets/FotoOlive.jpg" alt="Foto-Olive" id="pic" loading="lazy">
    </div>
    <div class="col-xl-6 col-sm-12" id="box" (mouseenter)="onAnimare1()" (mouseleave)="onAnimare1()" [@zoomHover]="state1" routerLink="/Foods">
      <h3 [translate]="'home.t1'"></h3>
      <p [translate]="'home.d1'"></p>
      <p [translate]="'home.ord'"></p>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-sm-12" id="box" (mouseenter)="onAnimare2()" (mouseleave)="onAnimare2()" [@zoomHover]="state2" routerLink="/Perfums">
      <h3 [translate]="'home.t2'"></h3>
      <p [translate]="'home.d2'"></p>
      <p [translate]="'home.ord'"></p>
    </div>
    <div class="col-xl-6 col-sm-12">
      <img src="../../assets/o'xygendiffusore.jpg" alt="o'xygen-diffusore" id="pic" loading="lazy">
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-sm-12">
      <img src="../../assets/PharmaBioFotocompleta.jpg" alt="Perfums" id="pic" loading="lazy">
    </div>
    <div class="col-xl-6 col-sm-12" id="box" (mouseenter)="onAnimare3()" (mouseleave)="onAnimare3()" [@zoomHover]="state3" routerLink="/Perfums">
      <h3 [translate]="'home.t3'"></h3>
      <p [translate]="'home.d3'"></p>
      <p [translate]="'home.ord'"></p>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-sm-12" id="box" (mouseenter)="onAnimare4()" (mouseleave)="onAnimare4()" [@zoomHover]="state4" routerLink="/Supplements">
      <h3 [translate]="'home.t4'"></h3>
      <p [translate]="'home.d4'"></p>
      <p [translate]="'home.ord'"></p>
    </div>
    <div class="col-xl-6 col-sm-12">
      <img src="../../assets/respirazionepastigliemielelimone-min.jpg" alt="pastiglie-miele-limone" id="pic" loading="lazy">
    </div>
  </div>-->

  <h1 [translate]="'cantum.title'"></h1>

  <div class="row">
    <div class="col-xl-6 col-sm-12">
      <img src="../../assets/heart-g4b666c548_1280.png" alt="Heart-disease" id="pic" loading="lazy">
    </div>
    <div class="col-xl-6 col-sm-12" id="box" (mouseenter)="onAnimare1()" (mouseleave)="onAnimare1()" [@zoomHover]="state1" routerLink="/Cantum">
      <h3 [translate]="'cantum.t1'"></h3>
      <p [translate]="'cantum.d1'"></p>
      <!--<p [translate]="'cantum.ord'"></p>-->
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-sm-12" id="box" (mouseenter)="onAnimare2()" (mouseleave)="onAnimare2()" [@zoomHover]="state2" routerLink="/Cantum">
      <h3 [translate]="'cantum.t2'"></h3>
      <p [translate]="'cantum.d2'"></p>
      <!--<p [translate]="'cantum.ord'"></p>-->
    </div>
    <div class="col-xl-6 col-sm-12">
      <img src="../../assets/blood-g7fc16d3eb_1920.jpg" alt="blood-pressure" id="pic" loading="lazy">
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-sm-12">
      <img src="../../assets/watermelon-g68cce8eb4_1920.jpg" alt="triglycerides" id="pic" loading="lazy">
    </div>
    <div class="col-xl-6 col-sm-12" id="box" (mouseenter)="onAnimare3()" (mouseleave)="onAnimare3()" [@zoomHover]="state3" routerLink="/Cantum">
      <h3 [translate]="'cantum.t3'"></h3>
      <p [translate]="'cantum.d3'"></p>
      <!--<p [translate]="'cantum.ord'"></p>-->
    </div>
  </div>
</body>
<!--<app-footer></app-footer>-->
