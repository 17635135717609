<div class="container-fluid" [ngSwitch]="whichLang">
  <div *ngSwitchDefault>
    <div class="row justify-content-center">
      <div class="card" *ngFor="let ambient of ambienti">
        <img src="{{ ambient.img }}" class="card-img-top" alt="{{ ambient.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ ambient.nome }}</h5>
          <p class="card-text">{{ ambient.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ ambient.descrPrezzo }}: {{ ambient.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(ambient.nome,ambient.img, ambient.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngSwitchCase="'en'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let ambient of ambients">
        <img src="{{ ambient.img }}" class="card-img-top" alt="{{ ambient.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ ambient.nome }}</h5>
          <p class="card-text">{{ ambient.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ ambient.descrPrezzo }}: {{ ambient.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(ambient.nome,ambient.img, ambient.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'de'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let ambient of ambienten">
        <img src="{{ ambient.img }}" class="card-img-top" alt="{{ ambient.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ ambient.nome }}</h5>
          <p class="card-text">{{ ambient.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ ambient.descrPrezzo }}: {{ ambient.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(ambient.nome,ambient.img, ambient.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'it'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let ambient of ambienti">
        <img src="{{ ambient.img }}" class="card-img-top" alt="{{ ambient.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ ambient.nome }}</h5>
          <p class="card-text">{{ ambient.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ ambient.descrPrezzo }}: {{ ambient.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(ambient.nome,ambient.img, ambient.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'fr'">
    <h3>Va arriver bientôt</h3>
  </div>
