import { Component, OnInit } from '@angular/core';
import { TranslationService } from '../shared/translation.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  dropdown = false;
  collapsed = true;
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  onEspandi(): void {
    this.dropdown = !this.dropdown;
    console.log('Ecco');
  }

  constructor(private translation: TranslationService) {
  }

  useLanguage(lang: string) {
    this.translation.useLanguage(lang);
  }
  ngOnInit() {
  }

}
