<div class="container-fluid" [ngSwitch]="whichLang">
  <div *ngSwitchDefault>
    <div class="row justify-content-center">
      <div class="card" *ngFor="let olio of pate">
        <img src="{{ olio.img }}" class="card-img-top" alt="{{ olio.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ olio.nome }}</h5>
          <p class="card-text">{{ olio.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ olio.descrPrezzo }}: {{ olio.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(olio.nome,olio.img, olio.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngSwitchCase="'en'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let oli of pates">
        <img src="{{ oli.img }}" class="card-img-top" alt="{{ oli.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ oli.nome }}</h5>
          <p class="card-text">{{ oli.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ oli.descrPrezzo }}: {{ oli.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(oli.nome,oli.img, oli.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'de'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let oli of paten">
        <img src="{{ oli.img }}" class="card-img-top" alt="{{ oli.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ oli.nome }}</h5>
          <p class="card-text">{{ oli.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ oli.descrPrezzo }}: {{ oli.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(oli.nome,oli.img, oli.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'it'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let olio of pate">
        <img src="{{ olio.img }}" class="card-img-top" alt="{{ olio.nome }}" loading="lazy">
        <div class="card-body">
          <h5 class="card-title">{{ olio.nome }}</h5>
          <p class="card-text">{{ olio.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ olio.descrPrezzo }}: {{ olio.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(olio.nome,olio.img, olio.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'fr'">
    <h3>Va arriver bientôt</h3>
  </div>
