import { Magliette } from '../magliette.model';
import { EventEmitter } from '@angular/core';



export class BasketService {
  basketChanged = new EventEmitter<Magliette[]>();
  private alimenti: Magliette[] = [];
  public idx = 0;

  private isEqual: number;

  setIdx(val: number) {
    this.idx = val;
  }

  getIdx() {
    return this.idx;
  }


  getAliments() {
    return this.alimenti.slice();
  }

  addAliment(alimenti: Magliette) {
    this.alimenti.push(alimenti);
    this.basketChanged.emit(this.alimenti.slice());
  }

  addAlimenti(alimenti: Magliette[]) {
    this.alimenti.push(...alimenti);
    this.basketChanged.emit(this.alimenti.slice());
  }

  deleteAlimenti(index: number) {
    this.alimenti.splice(this.idx, 1);
    this.basketChanged.next(this.alimenti.slice());
  }
}
