import { Component, Injectable, OnInit } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-supplements',
  templateUrl: './supplements.component.html',
  styleUrls: ['./supplements.component.scss'],
  animations: [
    inOutAnimation
  ]
})
@Injectable()
export class SupplementsComponent implements OnInit {
  idx = 0;
  whichLang = '';

  supplementi = [
    /*{ img: "../../../assets/geldetergentedelicatobio.jpg",
      nome: 'GEL DETERGENTE INTIMO DELICATO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },*/
   { img: "../../../assets/BOOSTERDRENANTESNELLENTE.jpg",
      nome: 'Booster drenante 240 ml',
      descrizione: "Il complesso enzimatico dell'ananas contrasta gli inestetismi della cellulite e favorisce la microcircolazione, aiutando a contrastare la sensazione di gambe pesanti. Il tè verde promuove 	l'equilibrio del peso corporeo",
      descrPrezzo: 'euro',
      cash: 24.90,
      qty: 1
   },
   /*{ img: "../../../assets/geldisinfettantemani.jpg",
      nome: 'GEL DISINFETTANTE MANI',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 5.70,
      qty: 1
   },
   { img: "../../../assets/aranciodolcebio.jpg",
      nome: 'ARANCIO DOLCE BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 7.50,
      qty: 1
   },
   { img: "../../../assets/lavandinosuperbio.jpg",
      nome: 'LAVANDINO SUPER BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/eucaliptoglobulobio.jpg",
      nome: 'EUCALIPTO GLOBULO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 7.90,
      qty: 1
   },
   { img: "../../../assets/garofanobio.jpg",
      nome: 'GAROFANO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/citronelladijavabio.jpg",
      nome: 'CITRONELLA JAVA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 7.95,
      qty: 1
   },*/
   { img: "../../../assets/niaoulibio.jpg",
      nome: 'Niaouli Bio olio essenziale 10 ml',
      descrizione: "Prendere un massimo di 2 gocce 3 volte al giorno (equivalente a 0. 19 ml) su una 	tavoletta Puressentiel neutra (o 1 cucchiaino di miele, olio d'oliva o 1/4 di cucchiaino di zucchero). Non usare puro senza supporto, né mescolare con acqua. Equivalenza 1ml = 32 gocce.",
      descrPrezzo: 'euro',
      cash: 9.90,
      qty: 1
   },
   /*{ img: "../../../assets/petitgrainbigaradebio.jpg",
      nome: 'PETIT GRAIN BIGARADE BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/pinosilvestrebio.jpg",
      nome: 'PINO SILVESTRE BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 6.90,
      qty: 1
   },*/
   { img: "../../../assets/ravintsarabio.jpg",
      nome: 'Ravintsara  Bio',
      descrizione: "Prendere un massimo di 2 gocce 3 volte al giorno (equivalente a 0. 15 ml) su una 	tavoletta Puressentiel neutra (o 1 cucchiaino di miele, olio d'oliva o 1/4 di cucchiaino di zucchero). Non usare puro senza supporto, né mescolare con acqua. Equivalenza: 1ml = 39 gocce",
      descrPrezzo: 'euro',
      cash: 9.90,
      qty: 1
   },
   /*{ img: "../../../assets/limonebio.jpg",
      nome: 'LIMONE BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/rosmarinoacineolobio.jpg",
      nome: 'ROSMARINO CINEOLO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.60,
      qty: 1
   },
   { img: "../../../assets/eucaliptoradiatabio.jpg",
      nome: 'EUCALIPTO RADIATA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/legnodirosaasiatica.jpg",
      nome: 'LEGNO DI ROSA ASIATICA',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.50,
      qty: 1
   },
   { img: "../../../assets/dragoncello.jpg",
      nome: 'DRAGONCELLO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/teatreealberodeltebio.jpg",
      nome: 'TEA TREE BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/gaultheriabio.jpg",
      nome: 'GAULTHERIA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.90,
      qty: 1
   },
   { img: "../../../assets/geraniobio.jpg",
      nome: 'GERANIO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/mentapiperitabioolio.jpg",
      nome: 'MENTA PIPERITA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 10.30,
      qty: 1
   },
   { img: "../../../assets/palmarosabio.jpg",
      nome: 'PALMAROSA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/lavandaspicabio.jpg",
      nome: 'LAVANDA SPICA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/mandarinoverdebioolioessenziale-min.jpg",
      nome: 'MANDARINO VERDE BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 10.50,
      qty: 1
   },
   { img: "../../../assets/origanocompattobio-min.jpg",
      nome: 'ORIGANO COMPATTO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/pompelmobio-min.jpg",
      nome: 'POMPELMO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/ylangylangbio-min.jpg",
      nome: 'YLANG YLANG BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.90,
      qty: 1
   },
   { img: "../../../assets/alloronobilebio.jpg",
      nome: 'ALLORO NOBILE BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 17.90,
      qty: 1
   },
   { img: "../../../assets/mirtobio-min.jpg",
      nome: 'MIRTO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/gineprobio.jpg",
      nome: 'GINEPRO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/maggioranabio-min.jpg",
      nome: 'MAGGIORANA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.30,
      qty: 1
   },
   { img: "../../../assets/cipressobio.jpg",
      nome: 'CIPRESSO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 10.50,
      qty: 1
   },
   { img: "../../../assets/rosmarinocanforatobio-min.jpg",
      nome: 'ROSMARINO CANFORA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.50,
      qty: 1
   },
   { img: "../../../assets/timoatimolobio-min.jpg",
      nome: 'TIMO LINALOLO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/lavandaverabio-min.jpg",
      nome: 'LAVANDA VERA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },*/
   { img: "../../../assets/salviasclareabio-min.jpg",
      nome: 'Salvia Sclarea Bio olio essenziale 5 ml',
      descrizione: 'Olio essenziale OEBBD (Olio essenziale botanicamente e biochimicamente definito) 	Equivalente 1ml = 33 gocce',
      descrPrezzo: 'euro',
      cash: 14.90,
      qty: 1
   },
   /*{ img: "../../../assets/geldetergentedelicatobio.jpg",
      nome: 'GEL DETERGENTE INTIMO DELICATO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/soslabialegelriparatore-min.jpg",
      nome: 'GEL SOS LABIALE 10 O.E.',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.30,
      qty: 1
   },
   { img: "../../../assets/zenzerofrescobioolioessenziale-min.jpg",
      nome: 'ZENZERO FRESCO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/s.o.spelletrattamentoanti-imperfezioni-min.jpg",
      nome: 'TRATTAMENTO IMPERFEZIONI PELLE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.90,
      qty: 1
   },
   { img: "../../../assets/cannelladiceylonbio.jpg",
      nome: 'CANNELLA CEYLON BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 15.90,
      qty: 1
   },*/
   { img: "../../../assets/respokfrizionepettorale-min.jpg",
      nome: 'Resp OK ® frizione pettorale 100 ml',
      descrizione: "Agitare il flacone prima dell'uso. Una volta al giorno, versare la lozione nell'incavo 	della mano e applicare strofinando sul petto fino a completo assorbimento. Lavarsi le mani dopo l'applicazione.",
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   /*{ img: "../../../assets/sosbuagel-min.jpg",
      nome: 'GEL SOS BUA',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/cistoladaniferobio.jpg",
      nome: 'CISTO LADANIFERO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 25.90,
      qty: 1
   },
   { img: "../../../assets/circolazionegelultrafresco.jpg",
      nome: 'GEL ULTRA FRESCO CIRCOLAZIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 14.95,
      qty: 1
   },
   { img: "../../../assets/snellentescrubexpress-min.jpg",
      nome: 'SCRUB SNELLENTE EXPRESS',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },*/
   { img: "../../../assets/lentiscoolioessenziale-min.jpg",
      nome: 'Lentisco olio essenziale 5 ml',
      descrizione: 'Olio essenziale OEBBD (Olio essenziale botanicamente e biochimicamente definito) 	Equivalente 1ml = 24 gocce',
      descrPrezzo: 'euro',
      cash: 24.90,
      qty: 1
   },
   /*{ img: "../../../assets/elicrisobioolioessenziale.jpg",
      nome: 'ELICRISO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 39.90,
      qty: 1
   },
   { img: "../../../assets/o'xygendiffusore.jpg",
      nome: "DIFFUSORE O'XYGEN",
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 49.90,
      qty: 1
   },
   { img: "../../../assets/diffusoreaultrasuonidiffuse&go.jpg",
      nome: 'DIFFUSORE A ULTRASUONI DIFFUSE & GO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 34.90,
      qty: 1
   },
   { img: "../../../assets/diffusoreaultrasuonibubble.jpg",
      nome: 'DIFFUSORE BUBBLE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 59.90,
      qty: 1
   },
   { img: "../../../assets/diffusoreultrasonicoapresaclip&diffuse.jpg",
      nome: 'DIFFUSORE CLIP&DIFFUSE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 29.90,
      qty: 1
   },
   { img: "../../../assets/diffusorel'conic.jpg",
      nome: "DIFFUSORE I’CONIC",
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 69.90,
      qty: 1
   },
   { img: "../../../assets/diffusorespoutnik.jpg",
      nome: 'DIFFUSORE SPOUTNIK',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 49.90,
      qty: 1
   },
   { img: "../../../assets/diffusoredioliessenzialiacaloredolce.jpg",
      nome: 'DIFFUSORE A CALORE DOLCE BLU',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 39.90,
      qty: 1
   },
   { img: "../../../assets/diffusoreelettricoapresaperoliessenziali.jpg",
      nome: 'DIFFUSORE ELETTRICO A PRESA GIREVOLE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 29.90,
      qty: 1
   },
   { img: "../../../assets/diffusoreumidificatoreovoid.jpg",
      nome: 'DIFFUSORE OVOID',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 39.90,
      qty: 1
   },
   { img: "../../../assets/o'xygendiffusore.jpg",
      nome: "DIFFUSORE O'XYGEN",
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 49.90,
      qty: 1
   },*/
   { img: "../../../assets/respirazionepastigliementa-eucalipto.jpg",
      nome: 'Respirazione pastiglie balsamiche menta-eucalipto 45 g',
      descrizione: "Le pastiglie Menta - Eucalipto sono composte da gomma di acacia associata a oli	essenziali di Menta dei campi e di Eucalipto globuloso. Senza zuccheri, all'estratto vegetale di stevia",
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/pastiglieagrumi-min.jpg",
      nome: 'Pastiglie agrumi 45 g',
      descrizione: "Le pastiglie agrumi sono composte da gomma d'acacia, combinata con estratti vegetali di	malva e altea, e oli essenziali di mandarino e limone. Senza zucchero, con estratto vegetale di stevia",
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/respirazionepastigliemielelimone-min.jpg",
      nome: 'Respirazione pastiglie miele limone 18 pastiglie',
      descrizione: "Conosciuto per le sue proprietà lenitive. Il miele proveniente dall'alveare è raccolto in 	maniera ecosolidale e responsabile",
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/respirazionepastigliementapino-min.jpg",
      nome: 'Respirazione pastiglie menta – pino 18 pastiglie',
      descrizione: "Noto per le sue proprietà lenitive. La freschezza piacevole e persistente del mentolo 	derivato dall'olio essenziale di menta campestre aiuta a liberare il naso e a respirare più  liberamente",
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/basiindispensabilicompresseneutre.jpg",
      nome: 'Compresse neutre 30 compresse',
      descrizione: 'Supporto per la somministrazione orale di oli essenziali. Senza zucchero. Senza glutine. Senza conservanti',
      descrPrezzo: 'euro',
      cash: 5.60,
      qty: 1
   },
   { img: "../../../assets/respokcapsulepersuffumigi-min.jpg",
      nome: 'Resp OK® capsule per suffimigi 15 capsule',
      descrizione: '8 OLI ESSENZIALI BIOLOGICI: Eucalipto globulus, Menta crespa, Menta piperita, Niaouli, Pino, Ravintsara, Tea Tree e Timo timolo con proprietà purificanti, lenitive e detergenti',
      descrPrezzo: 'euro',
      cash: 11.90,
      qty: 1
   },
   /*{ img: "../../../assets/respirazioneintegratorealimentarebio-min.jpg",
      nome: 'CAPSULE INTEGRATORE ALIMENTARE BIO RESP OK®',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },*/
   { img: "../../../assets/sonnisereni+integratorealimentarebio-min.jpg",
      nome: 'Sonni sereni + integratore alimentare bio 30 capsule',
      descrizione: "L'integratore alimentare biologico Puressentiel Sonni sereni contiene oli essenziali di Arancio amaro biologico, Mandarino verde biologico e Lavanda biologica che favoriscono il sonno, il rilassamento e il benessere mentale",
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   /*{ img: "../../../assets/Miscelaviaggioinsicilia.jpg",
      nome: 'MISCELA VIAGGIO IN SICILIA',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/miscelaariapura-min.jpg",
      nome: 'MISCELA ARIA PURA PER DIFFUSIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/oliessenzialiperdiffusionemiscelarespok-min.jpg",
      nome: 'MISCELA RESP OK® PER DIFFUSIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/oliessenzialiperdiffusionezen-min.jpg",
      nome: 'MISCELA ZEN PER DIFFUSIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/oliessenzialiperdiffusionepasseggiatanellaforesta-min.jpg",
      nome: 'MISCELA PASSEGGIATA FORESTA PER DIFFUSIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/oliessenzialiperdiffusioneenergiapositiva-min.jpg",
      nome: 'MISCELA ENERGIA POSITIVA PER DIFFUSIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },*/
   { img: "../../../assets/oliessenzialiperdiffusionecocooning-min.jpg",
      nome: 'Oli essenziali per diffusione cocooning 30 ml',
      descrizione: "5 deliziosi oli essenziali che evocano ricordi d'infanzia, veglie, serate e relax accanto al 	fuoco. Ideale per creare un ambiente caldo e confortevole per il corpo e la mente",
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   /*{ img: "../../../assets/oliessenzialiperdiffusionehappy-min.jpg",
      nome: 'MISCELA HAPPY PER DIFFUSIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/oliessenzialiperdiffusionemeditation-min.jpg",
      nome: 'MISCELA MEDITATION PER DIFFUSIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/oliessenzialiperdiffusioneprovence-min.jpg",
      nome: 'MISCELA PROVENCE PER DIFFUSIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/oliessenzialiperdiffusionediffusionecitronella-min.jpg",
      nome: 'MISCELA CITRONELLA PER DIFFUSIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },*/
   { img: "../../../assets/sonniserenispray.jpg",
      nome: "Sonni sereni spray per l'aria 12 oli essenziali 200 ml",
      descrizione: 'Aiuta a: giorno Favorire il rilassamento e il riposo, calmare la tensione. Notte: incoraggiare il sonno, passare notti calme, tranquille e ristoratrici',
      descrPrezzo: 'euro',
      cash: 19.90,
      qty: 1
   },
   /*{ img: "../../../assets/spraypidocchipreventivo.jpg",
      nome: 'SPRAY PREVENTIVO PIDOCCHI',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.90,
      qty: 1
   },*/
   { img: "../../../assets/respirazionespraynasaleipertonico-min.jpg",
      nome: 'Spray nasale ipertonico bio 15 ml',
      descrizione: 'Azione immediata: decongestiona dai primi minuti! Libera il naso per una migliore respirazione Dà sollievo alla mucosa nasale irritata Umidifica e pulisce i passaggi nasali bloccati',
      descrPrezzo: 'euro',
      cash: 11.95,
      qty: 1
   },
   /*{ img: "../../../assets/purificantelozionespraymani-min.jpg",
      nome: 'SPRAY PURIFICANTE 41 O.E',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 11.95,
      qty: 1
   },
   { img: "../../../assets/respirazionespraynasaleipertonico-min.jpg",
      nome: 'SPRAY NASALE IPERTONICO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 11.95,
      qty: 1
   },*/
   { img: "../../../assets/respirazionespraygola-min.jpg",
      nome: 'Respirazione spray gola 15 ml',
      descrizione: 'Olio essenziale di menta: utile per la funzionalità delle prime vie respiratorie. Olio essenziale di timo: favorisce il benessere della gola',
      descrPrezzo: 'euro',
      cash: 10.90,
      qty: 1
   },
   /*{ img: "../../../assets/sospunturespraybimbodoppiaazioneprotezione+effettolenitivo-min.jpg",
      nome: 'SOS PUNTURE SPRAY BIMBO PROTEZIONE + EFFETTO LENITIVO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/s.o.sinsettisprayantipunturarepellente+lenitivo-min.jpg",
      nome: 'SPRAY SOS INSETTI',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 19.90,
      qty: 1
   },
   { img: "../../../assets/respoksprayperl'aria-min.jpg",
      nome: 'SPRAY PER L’ARIA RESP OK®',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 19.90,
      qty: 1
   },
   { img: "../../../assets/circolazionespraytonicoexpress.jpg",
      nome: 'SPRAY TONICO EXPRESS',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 16.95,
      qty: 1
   },
   { img: "../../../assets/purificantesprayperl'aria-min.jpg",
      nome: 'SPRAY PURIFICANTE 41 O.E.',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 21.90,
      qty: 1
   },
   { img: "../../../assets/profumoperambientedolcezzadiprovenza-min.jpg",
      nome: 'PROFUMO AMBIENTE DOLCEZZA PROVENZA',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 11.90,
      qty: 1
   },
   { img: "../../../assets/profumoperambientedolcezzavivificante-min.jpg",
      nome: 'PROFUMO AMBIENTE DOLCEZZA VIVIFICANTE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 11.90,
      qty: 1
   },
   { img: "../../../assets/profumoperambientedolcezzadiagrumi-min.jpg",
      nome: 'PROFUMO AMBIENTE DOLCEZZA AGRUMI',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 11.90,
      qty: 1
   },
   { img: "../../../assets/cremamultilenitivabimbo.jpg",
      nome: 'CREMA MULTI-LENITIVA SOS INSETTI BIMBI',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 10.90,
      qty: 1
   },
   { img: "../../../assets/incensoolioessenziale-min.jpg",
      nome: 'INCENSO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 14.50,
      qty: 1
   },
   { img: "../../../assets/sosinsetticremamulti-lenitiva-min.jpg",
      nome: 'CREMA MULTI-LENITIVA SOS INSETTI ADULTI',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 10.90,
      qty: 1
   },
   { img: "../../../assets/circolazionecremafreschezzaidratante.jpg",
      nome: 'CREMA IDRATANTE CIRCOLAZIONE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 14.95,
      qty: 1
   },
   { img: "../../../assets/snellentecremasnellenteexpress-min.jpg",
      nome: 'CREMA SNELLENTE EXPRESS CON CAFFEINA',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 29.90,
      qty: 1
   },
   { img: "../../../assets/bellezzadellapelleolioseccoscintillante.jpg",
      nome: 'OLIO SECCO SCINTILLANTE BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 23.50,
      qty: 1
   },
   { img: "../../../assets/oliovegetalemandorladolcebio.jpg",
      nome: 'OLIO VEGETALE DI MANDORLA DOLCE BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/oliovegetalebiorosamosqueta.jpg",
      nome: 'OLIO VEGETALE DI ROSA MOSQUETA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/oliovegetalebiomacadamia-min.jpg",
      nome: 'OLIO VEGETALE DI MACADAMIA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.80,
      qty: 1
   },
   { img: "../../../assets/oliovegetalebioargan-min.jpg",
      nome: 'OLIO VEGETALE DI ARGAN BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.80,
      qty: 1
   },
   { img: "../../../assets/oliovegetalebiojojoba-min.jpg",
      nome: 'OLIO VEGETALE DI JOJOBA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.80,
      qty: 1
   },*/
   { img: "../../../assets/sforzomuscolareoliopermassaggiobiologicoarnica-gaultheria-min.jpg",
      nome: 'Sforzo muscolare olio per massaggio biologico arnica – gaultheria 100 ml',
      descrizione: "Muscle Exertion Treatment Oil è ideale per l'atleta occasionale o professionista",
      descrPrezzo: 'euro',
      cash: 16.90,
      qty: 1
   },
   /*{ img: "../../../assets/rilassanteoliopermassaggiobiologicolavanda-neroli-min.jpg",
      nome: 'OLIO DA MASSAGGIO RELAX BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/snellanteolioditrattamentolavante-min.jpg",
      nome: 'OLIO DI TRATTAMENTO LAVANTE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/snellenteoliodamassaggiobiologico-min.jpg",
      nome: 'OLIO DA MASSAGGIO BIO SNELLENTE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 19.90,
      qty: 1
   },
   { img: "../../../assets/bellezzadellapelleolioditrattamentoessenzialebio.jpg",
      nome: 'OLIO TRATTAMENTO ESSENTIEL BIO CORPO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 16.90,
      qty: 1
   },*/
   { img: "../../../assets/snellenteoliosecco-min.jpg",
      nome: 'SNELLENTE OLIO SECCO 100 ml',
      descrizione: "Questo trattamento aiuta a ridurre l'effetto buccia d'arancia e a migliorare l'aspetto 	della cellulite. La sua texture non grassa e idrata penetrando molto facilmente e non lascia tracce",
      descrPrezzo: 'euro',
      cash: 24.90,
      qty: 1
   },
   /*{ img: "../../../assets/bellezzadellapelleelixiressentielbioolioditrattamentoviso.jpg",
      nome: 'OLIO TRATTAMENTO VISO ELIXIR ESSENTIEL BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 29.50,
      qty: 1
   },
   { img: "../../../assets/baseneutrabagno&doccia.jpg",
      nome: 'BASE NEUTRA BAGNO/DOCCIA',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.90,
      qty: 1
   },*/
   { img: "../../../assets/respokessenzearomaticheperbagnodoccia-min.jpg",
      nome: 'Resp OK® essenze aromatiche per bagno doccia 100ml',
      descrizione: 'Emana un profumo purificante per aiutare: portare conforto e benessere dai primi freddi',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   /*{ img: "../../../assets/sonnisereniessenzearomaticheperbagnodoccia-min.jpg",
      nome: 'ESSENZE PER BAGNO DOCCIA RELAX',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/articolazioni&muscolicryopureroller.jpg",
      nome: 'CRYO PURE® ROLLER',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 15.90,
      qty: 1
   },
   { img: "../../../assets/articolazioni&muscolicryopuregel.jpg",
      nome: 'CRYO PURE® GEL',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.90,
      qty: 1
   },
   { img: "../../../assets/articolazioni&muscoligel.jpg",
      nome: 'ARTICOLAZIONI & MUSCOLI GEL',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.90,
      qty: 1
   },
   { img: "../../../assets/articolazioni&muscoliroller.jpg",
      nome: 'ARTICOLAZIONI & MUSCOLI ROLLER',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 15.90,
      qty: 1
   },
   { img: "../../../assets/articolazioni&muscolifrizionearnica.jpg",
      nome: 'FRIZIONE ARNICA & 14 O.E.',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 19.90,
      qty: 1
   },
   { img: "../../../assets/articolazioni&muscolicryopurespray.jpg",
      nome: 'CRYO PURE® SPRAY',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 16.90,
      qty: 1
   },*/
   { img: "../../../assets/rollerrotonditaribelli-min.jpg",
      nome: 'Roller rotondità ribelli 75 ml',
      descrizione: 'Il massaggio dinamico dei tessuti adiposi è riconosciuto per migliorare l’apparenza 	estetica della cellulite sulle zone ribelli e resistenti alle diete (cosce, glutei, pancia...). L’azione meccanica 	lipolevigante della sfera massaggiante del Roller Rotondità Ribelli',
      descrPrezzo: 'euro',
      cash: 16.90,
      qty: 1
   },
   /*{ img: "../../../assets/sosstressroller-min.jpg",
      nome: 'ROLLER STRESS',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/s.o.sinsettirollerlenitivo-min.jpg",
      nome: 'ROLLER MULTI-LENITIVO SOS INSETTI',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/malditestaroller-min.jpg",
      nome: 'ROLLER MAL DI TESTA',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/maldiviaggioroller-min.jpg",
      nome: 'ROLLER MAL DI VIAGGIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/articolazioni&muscolicryopureroller.jpg",
      nome: 'CRYO PURE® ROLLER',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 15.90,
      qty: 1
   },
   { img: "../../../assets/articolazioni&muscoliroller.jpg",
      nome: 'ARTICOLAZIONI & MUSCOLI ROLLER',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 15.90,
      qty: 1
   },
   { img: "../../../assets/idrolatofiorid'arancio-min.jpg",
      nome: 'IDROLATO DI FIOR D’ARANCIO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/idrolatofiordaliso.jpg",
      nome: 'IDROLATO DI FIORDALISO BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/idrolatorosa-min.jpg",
      nome: 'IDROLATO DI ROSA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/idrolatolavanda-min.jpg",
      nome: 'IDROLATO DI LAVANDA BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },*/
   { img: "../../../assets/sciroppodolcenottebambinibio-min.jpg",
      nome: 'Sciroppo dolce notte bambini bio 125 ml',
      descrizione: '’Camomilla, Tiglio, Passiflora per favorire il sonno e il rilassamento, e Papavero contro gli 	stati di tensione localizzati',
      descrPrezzo: 'euro',
      cash: 14.20,
      qty: 1
   },
   { img: "../../../assets/respirazionesciroppotosse-min.jpg",
      nome: 'Respirazione sciroppo tosse 125 ml',
      descrizione: "Aiuta a: Liberare le vie respiratorie superiori idratando il muco Proteggere la mucosa in 	modo duraturo riducendo l'irritazione e la frequenza della tosse. Fornire un leggero effetto di 	raffreddamento che aiuta a sostenere il dolore causato dall'irritazione",
      descrPrezzo: 'euro',
      cash: 12.50,
      qty: 1
   },
   /*{ img: "../../../assets/respokinalatore-min.jpg",
      nome: 'INALATORE RESP OK®',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 6.95,
      qty: 1
   },
   { img: "../../../assets/tonoinalatore-min.jpg",
      nome: 'INALATORE TONO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/tagliafameinalatore-min.jpg",
      nome: 'INALATORE TAGLIA FAME',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/diffusoredivaporeinalatore&hammamfacciale.jpg",
      nome: 'DIFFUSORE DI VAPORE INALATORE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 59.90,
      qty: 1
   },
   { img: "../../../assets/respokinalazioneumida-min.jpg",
      nome: 'INALAZIONE UMIDA RESP OK®',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 10.90,
      qty: 1
   },
   { img: "../../../assets/respokinalazioneumida-min.jpg",
      nome: 'INALAZIONE UMIDA RESP OK®',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 10.90,
      qty: 1
   },
   { img: "../../../assets/lozionetrattantetrattamentoeadicaleconpettine-min.jpg",
      nome: 'LOZIONE PIDOCCHI + PETTINE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 19.90,
      qty: 1
   },
   { img: "../../../assets/shampootrattante2in1anti-pidocchi-min.jpg",
      nome: 'SHAMPOO TRATTANTE 2 IN 1 + PETTINE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 18.50,
      qty: 1
   },
   { img: "../../../assets/shampootrattante2in1anti-pidocchi-min.jpg",
      nome: 'SHAMPOO TRATTANTE 2 IN 1 + PETTINE',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 18.50,
      qty: 1
   },
   { img: "../../../assets/snellentecofanettoanticellulite-min.jpg",
      nome: 'COFANETTO 2 OLI SECCHI 2X100ML + VENTOSA',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 49.50,
      qty: 1
   },*/
   { img: "../../../assets/bellezzadellapellehomelifting.jpg",
      nome: 'Shampoo trattante 2 in 1 + pettine 150 ml',
      descrizione: 'Puressentiel Anti-Lice Treatment Shampoo-Mask elimina pidocchi, larve e lendini lasciando 	i capelli puliti in un solo passaggio',
      descrPrezzo: 'euro',
      cash: 39.90,
      qty: 1
   },
   /*{ img: "../../../assets/RESPOKbalsamodamassaggiopettorale-min.jpg",
      nome: 'BALSAMO BAMBINO RESP OK® DAI 3 ANNI',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.90,
      qty: 1
   },
   { img: "../../../assets/respokbalsamodamassaggio-min.jpg",
      nome: 'BALSAMO RESP OK®',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 12.90,
      qty: 1
   },
   { img: "../../../assets/pouxdouxshampooperusoquotidianoconcertificazionebio-min.jpg",
      nome: 'SHAMPOO POUXDOUX BIO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 10.90,
      qty: 1
   },
   { img: "../../../assets/sonniserenibalsamodamassaggiocalmantebimbo-min.jpg",
      nome: 'BALSAMO DA MASSAGGIO CALMANTE PER BIMBO',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/respokbalsamopettoralebimbo-min.jpg",
      nome: 'BALSAMO BIMBO RESP OK® DAI 6 MESI',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/camomillaromanaolioessenziale.jpg",
      nome: 'CAMOMILLA ROMANA',
      descrizione: '',
      descrPrezzo: 'euro',
      cash: 34.90,
      qty: 1
   },*/
   { img: "../../../assets/snellentecofanettoanticellulite-min.jpg",
    nome: "Cofanetto 2 oli secchi 2x100ml + ventosa",
    descrizione: "Questo trattamento aiuta a ridurre l'effetto buccia d'arancia e a migliorare l'aspetto della 	cellulite",
    descrPrezzo: 'euro',
    cash: 49.50,
    qty: 1
 },
 { img: "../../../assets/sonniserenibalsamodamassaggiocalmantebimbo-min.jpg",
    nome: 'Sonni sereni balsamo da massaggio calmante bimbo 30 ml',
    descrizione: 'La sua composizione a base di estratti naturali di Camomilla matricaria, Lavanda vera bio, 	Arancio bio e Maggiorana bio libera effluvi aromatici calmanti e rilassanti che favoriscono il 	rilassamento. Questo balsamo da massaggio è ideale per calmare e preparare al riposo i più piccoli a 	partire dai 6 mesi',
    descrPrezzo: 'euro',
    cash: 9.95,
    qty: 1
 },
 { img: "../../../assets/sonniserenibalsamodamassaggiocalmantebimbo-min.jpg",
    nome: 'Sonni sereni balsamo da massaggio calmante bimbo 30 ml',
    descrizione:'La sua composizione a base di estratti naturali di Camomilla matricaria, Lavanda vera 	bio, Arancio bio e Maggiorana bio libera effluvi aromatici calmanti e rilassanti che favoriscono il 	rilassamento. Questo balsamo da massaggio è ideale per calmare e preparare al riposo i più piccoli a 	partire dai 6 mesi',
    descrPrezzo: 'euro',
    cash: 9.95,
    qty: 1
 },

  ];
  supplements = [
    { img: "../../../assets/BOOSTERDRENANTESNELLENTE.jpg",
      nome: 'Draining booster 240 ml',
      descrizione: 'The enzymatic complex of pineapple counteracts the imperfections of cellulite and 	promotes microcirculation, helping to counteract the feeling of heavy legs. Green tea promotes body weight balance',
      descrPrezzo: 'euro',
      cash: 24.90,
      qty: 1
   },
   { img: "../../../assets/niaoulibio.jpg",
      nome: 'Niaouli bio essential oil 10 ml',
      descrizione: "Take a maximum of 2 drops 3 times a day (equivalent to 0. 19 ml) on a neutral Puressentiel 	tablet (or 1 teaspoon of honey, olive oil or 1/4 teaspoon of sugar). Do not use pure without support, nor mix with water. Equivalence 1ml = 32 drops",
      descrPrezzo: 'euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/ravintsarabio.jpg",
      nome: 'Ravintsara bio essential oil 5 ml',
      descrizione: 'Take a maximum of 2 drops 3 times a day (equivalent to 0.15ml) on a neutral Puressentiel 	tablet (or 1 teaspoon of honey, olive oil or 1/4 teaspoon of sugar). Do not use pure without support, nor 	mix with water. Equivalence: 1ml = 39 drops',
      descrPrezzo: 'euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/salviasclareabio-min.jpg",
      nome: 'Organic Clary Sage essential oil 5 ml',
      descrizione: 'OEBBD essential oil (Botanically and biochemically defined essential oil) Equivalent 1ml = 	33 drops',
      descrPrezzo: 'euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/respokfrizionepettorale-min.jpg",
      nome: 'Resp ok® chest clutch 100 ml',
      descrizione: 'Shake the bottle before use. Once a day, pour the lotion into the hollow of the hand and 	apply by rubbing on the chest until completely absorbed. Wash your hands after application',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/lentiscoolioessenziale-min.jpg",
      nome: 'Lentisk essential oil 5 ml',
      descrizione: 'OEBBD essential oil (Botanically and biochemically defined essential oil) Equivalent 1ml = 	24 drops',
      descrPrezzo: 'euro',
      cash: 24.90,
      qty: 1
   },
   { img: "../../../assets/respirazionepastigliementa-eucalipto.jpg",
      nome: 'Breathing balsamic mint-eucalyptus pads 45 g',
      descrizione: 'Mint - Eucalyptus tablets are composed of acacia gum associated with essential oils of Mint 	of the fields and globular Eucalyptus. Sugar free, with stevia plant extract',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/pastiglieagrumi-min.jpg",
      nome: 'Citrus tablets 45 g',
      descrizione: 'The citrus pastilles are composed of acacia gum, combined with plant extracts of mallow 	and marshmallow, and essential oils of mandarin and lemon. Sugar-free, with stevia plant extract',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/respirazionepastigliemielelimone-min.jpg",
      nome: 'Breathing pads honey lemon 18 pads',
      descrizione: 'Known for its soothing properties. Honey from the hive is harvested in an eco-friendly and 	responsible way',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/respirazionepastigliementapino-min.jpg",
      nome: 'Breathing pads mint - pine 18 pads',
      descrizione: 'Known for its soothing properties. The pleasant and persistent freshness of menthol 	derived from the essential oil of field mint helps to clear the nose and breathe more freely',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/basiindispensabilicompresseneutre.jpg",
      nome: 'Neutral tablets 30 tablets',
      descrizione: 'Support for oral administration of essential oils. Unsweetened. Gluten free. Preservative free',
      descrPrezzo: 'euro',
      cash: 5.60,
      qty: 1
   },
   { img: "../../../assets/respokcapsulepersuffumigi-min.jpg",
      nome: 'Resp ok® capsules for fumigations 15 capsules',
      descrizione: '8 ORGANIC ESSENTIAL OILS: Eucalyptus globulus, Crepe Mint, Peppermint, Niaouli, Pine, 	Ravintsara, Tea Tree and Thyme Thymol with purifying, soothing and cleansing properties',
      descrPrezzo: 'euro',
      cash: 11.90,
      qty: 1
   },
   { img: "../../../assets/sonnisereni+integratorealimentarebio-min.jpg",
      nome: 'Peaceful sleep + bio food supplement 30 capsules',
      descrizione: 'The Puressentiel Sleep Serene organic food supplement contains organic bitter orange, organic green mandarin and organic lavender essential oils that promote sleep, relaxation and mental well-being',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/oliessenzialiperdiffusionecocooning-min.jpg",
      nome: 'Essential oils for cocooning diffusion 30 ml',
      descrizione: '5 delicious essential oils that evoke childhood memories, wakes, evenings and relaxation 	by the fire. Ideal for creating a warm and comfortable environment for the body and mind',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/sonniserenispray.jpg",
      nome: 'Peaceful sleep air spray 12 essential oils 200 ml',
      descrizione: 'Helps: day Promote relaxation and rest, Calm tension. Night: encourage sleep, spend calm, 	peaceful and restorative nights',
      descrPrezzo: 'euro',
      cash: 19.90,
      qty: 1
   },
   { img: "../../../assets/respirazionespraynasaleipertonico-min.jpg",
      nome: 'Bio hypertonic nasal spray 15 ml',
      descrizione: 'Immediate action: decongests from the first minutes! Clear the nose for better breathing 	Relieves irritated nasal mucosa Moistens and cleans blocked nasal passages',
      descrPrezzo: 'euro',
      cash: 11.95,
      qty: 1
   },
   { img: "../../../assets/respirazionespraygola-min.jpg",
      nome: 'Throat breathing spray 15 ml',
      descrizione: 'Mint essential oil: useful for the functionality of the upper respiratory tract. Thyme 	essential oil: promotes the well-being of the throat',
      descrPrezzo: 'euro',
      cash: 10.90,
      qty: 1
   },
   { img: "../../../assets/sforzomuscolareoliopermassaggiobiologicoarnica-gaultheria-min.jpg",
      nome: 'Muscle effort organic massage oil arnica - wintergreen 100 ml',
      descrizione: 'Muscle Exertion Treatment Oil is ideal for the casual or professional athlete',
      descrPrezzo: 'euro',
      cash: 16.90,
      qty: 1
   },
   { img: "../../../assets/snellenteoliosecco-min.jpg",
      nome: 'SLIMMING DRY OIL 100 ml',
      descrizione: 'This treatment helps reduce the orange peel effect and improve the appearance of 	cellulite. Its non-greasy texture moisturizes penetrating very easily and leaves no traces',
      descrPrezzo: 'euro',
      cash: 24.90,
      qty: 1
   },
   { img: "../../../assets/respokessenzearomaticheperbagnodoccia-min.jpg",
      nome: 'Resp ok® aromatic essences for shower bath 100 ml',
      descrizione: 'It gives off a purifying scent to help: bring comfort and well-being from the first cold',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/rollerrotonditaribelli-min.jpg",
      nome: 'Rebel Roundness Roller 75 ml',
      descrizione: 'The dynamic massage of adipose tissues is recognized for improving the aesthetic 	appearance of cellulite on rebellious and diet-resistant areas (thighs, buttocks, stomach ...). The lipolevigating mechanical action of the massaging sphere of the Rebellious Roundness Roller',
      descrPrezzo: 'euro',
      cash: 16.90,
      qty: 1
   },
   { img: "../../../assets/sciroppodolcenottebambinibio-min.jpg",
      nome: "Organic children's night sweet syrup 125 ml",
      descrizione: 'Chamomile, Linden, Passionflower to promote sleep and relaxation, and Poppy against 	localized states of tension',
      descrPrezzo: 'euro',
      cash: 14.20,
      qty: 1
   },
   { img: "../../../assets/respirazionesciroppotosse-min.jpg",
      nome: 'Breathing cough syrup 125 ml',
      descrizione: 'Helps to: Clear the upper respiratory tract by hydrating the mucus Protect the mucous 	membrane in a lasting way by reducing irritation and the frequency of coughing. Providing a slight cooling 	effect that helps support pain caused by irritation',
      descrPrezzo: 'euro',
      cash: 12.50,
      qty: 1
   },
   { img: "../../../assets/bellezzadellapellehomelifting.jpg",
      nome: '2 in 1 treatment shampoo + 150 ml comb 2 in 1 treatment shampoo + 150 ml comb',
      descrizione: 'Puressentiel Anti-Lice Treatment Shampoo-Mask eliminates lice, larvae and nits leaving the 	hair clean in one step',
      descrPrezzo: 'euro',
      cash: 18.50,
      qty: 1
   },
   { img: "../../../assets/snellentecofanettoanticellulite-min.jpg",
      nome: 'Box of 2 dry oils 2x100ml + suction cup',
      descrizione: 'This treatment helps reduce the orange peel effect and improve the appearance of 	cellulite',
      descrPrezzo: 'euro',
      cash: 49.50,
      qty: 1
   },
   { img: "../../../assets/sonniserenibalsamodamassaggiocalmantebimbo-min.jpg",
      nome: 'Peaceful sleeps calming baby massage balm 30 ml',
      descrizione: 'Its composition based on natural extracts of matricaria chamomile, organic true lavender, organic orange and organic marjoram releases calming and relaxing aromatic scents that promote 	relaxation. This massage balm is ideal for calming and preparing children from 6 months to rest',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/sonni sereni balsamo da massaggio calmante bimbo-min..jpg",
      nome: 'Peaceful sleeps calming baby massage balm 30 ml',
      descrizione: 'Its composition based on natural extracts of matricaria chamomile, organic true lavender, organic orange and organic marjoram releases calming and relaxing aromatic scents that promote 	relaxation. This massage balm is ideal for calming and preparing children from 6 months to rest',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },

  ];
  supplementen = [
    { img: "../../../assets/BOOSTERDRENANTESNELLENTE.jpg",
      nome: 'Drainage-Booster 240 ml',
      descrizione: 'Der Ananas-Enzymkomplex wirkt den Unvollkommenheiten der Cellulite entgegen und 	fördert die Mikrozirkulation, was dem Gefühl von schweren Beinen entgegenwirkt. Grüner Tee fördert 	den Ausgleich des Körpergewichts',
      descrPrezzo: 'euro',
      cash: 24.90,
      qty: 1
   },
   { img: "../../../assets/niaoulibio.jpg",
      nome: 'Niaouli bio ätherisches Öl 10 ml',
      descrizione: 'Nehmen Sie 3-mal täglich maximal 2 Tropfen (entspricht 0,19 ml) auf eine neutrale 	Puressentiel-Tablette (oder 1 Teelöffel Honig, Olivenöl oder 1/4 Teelöffel Zucker).Nicht pur ohne 	Unterstützung verwenden, auch nicht mit Wasser mischen. Äquivalenz 1ml = 32 Tropfen',
      descrPrezzo: 'euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/ravintsarabio.jpg",
      nome: 'Ravintsara bio ätherisches Öl 5 ml',
      descrizione: 'Nehmen Sie 3 mal täglich maximal 2 Tropfen (entspricht 0. 15 ml) auf eine neutrale 	Puressentiel-Tablette (oder 1 Teelöffel Honig, Olivenöl oder 1/4 Teelöffel Zucker). Nicht pur ohne 	Unterstützung verwenden, auch nicht mit Wasser mischen. Äquivalenz: 1ml = 39 Tropfen',
      descrPrezzo: 'euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/salviasclareabio-min.jpg",
      nome: 'Ätherisches Öl Muskatellersalbei 5 ml',
      descrizione: 'Ätherisches Öl OEBBD (Botanically and Biochemically Defined Essential Oil) Äquivalent 	1ml= 33 Tropfen',
      descrPrezzo: 'euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/respokfrizionepettorale-min.jpg",
      nome: 'Resp ok® Brusteinreibung 100 ml',
      descrizione: 'Flasche vor Gebrauch schütteln. Einmal täglich die Lotion in die Handinnenfläche geben 	und durch Reiben über die Brust auftragen, bis sie vollständig eingezogen ist. Waschen Sie sich nach der 	Anwendung die Hände',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/lentiscoolioessenziale-min.jpg",
      nome: 'Ätherisches Öl Lentisk 5 ml',
      descrizione: 'Ätherisches Öl OEBBD (Botanically and Biochemically Defined Essential Oil) Äquivalent 1ml= 24 Tropfen',
      descrPrezzo: 'euro',
      cash: 24.90,
      qty: 1
   },
   { img: "../../../assets/respirazionepastigliementa-eucalipto.jpg",
      nome: 'Atmungsaktive Balsamico-Pads Minze-Eukalyptus 45 g',
      descrizione: 'Minze-Eukalyptus-Lutschtabletten werden aus Akaziengummi in Kombination mit den 	ätherischen Ölen von Ackerminze und Eukalyptus globulus hergestellt. Zuckerfrei, mit Stevia- Pflanzenextrakt.',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/pastiglieagrumi-min.jpg",
      nome: 'Citrus Tabletten 45 g',
      descrizione: 'Citrus Lutschtabletten bestehen aus Akaziengummi, kombiniert mit Pflanzenextrakten aus 	Malve und Eibisch, sowie ätherischen Ölen aus Mandarine und Zitrone. Zuckerfrei, mit Stevia-	Pflanzenextrakt',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/respirazionepastigliemielelimone-min.jpg",
      nome: 'Atemtabletten Honig-Zitrone 18 Tabletten',
      descrizione: 'Bekannt für seine beruhigenden Eigenschaften. Der Honig aus dem Bienenstock wird auf 	umweltfreundliche und verantwortungsvolle Weise geerntet',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/respirazionepastigliementapino-min.jpg",
      nome: 'Atemtabletten Minze - Kiefer 18 Tabletten',
      descrizione: 'Bekannt für seine beruhigenden Eigenschaften. Die angenehme, anhaltende Frische des 	Menthols aus dem ätherischen Öl der Ackerminze hilft, die Nase frei zu machen und freier zu atmen',
      descrPrezzo: 'euro',
      cash: 8.90,
      qty: 1
   },
   { img: "../../../assets/basiindispensabilicompresseneutre.jpg",
      nome: 'Neutrale Tabletten 30 Tabletten',
      descrizione: 'Unterstützung bei der oralen Verabreichung von ätherischen Ölen. Zuckerfrei. Glutenfrei. 	Ohne Konservierungsstoffe',
      descrPrezzo: 'euro',
      cash: 5.60,
      qty: 1
   },
   { img: "../../../assets/respokcapsulepersuffumigi-min.jpg",
      nome: 'Resp ok® Kapseln zur Suffumigation 15 Kapseln',
      descrizione: '8 BIO-ÄSSERÖLE: Eukalyptus globulus, Kreppminze, Pfefferminze, Niaouli, Kiefer, 	Ravintsara, Teebaum und Thymian Thymol mit reinigenden, beruhigenden und reinigenden 	Eigenschaften',
      descrPrezzo: 'euro',
      cash: 11.90,
      qty: 1
   },
   { img: "../../../assets/sonnisereni+integratorealimentarebio-min.jpg",
      nome: 'Gelassener Schlaf + Bio-Nahrungsergänzung 30 Kapseln',
      descrizione: 'Das Bio-Nahrungsergänzungsmittel Serene Sleep von Puressentiel enthält ätherische Öle 	aus Bio-Bitterorange, Bio-Grüner Mandarine und Bio-Lavendel, die Schlaf, Entspannung und geistiges 	Wohlbefinden fördern',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/oliessenzialiperdiffusionecocooning-min.jpg",
      nome: 'Ätherische Öle für Cocooning Diffusion 30 ml',
      descrizione: '5 köstliche ätherische Öle, die Erinnerungen an die Kindheit, an Nachtwachen, Abende und 	Entspannung am Feuer wecken. Ideal, um eine warme und angenehme Umgebung für Körper und Geist zu schaffen',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/sonniserenispray.jpg",
      nome: 'Peaceful Sleep Air Spray 12 ätherische Öle 200 ml',
      descrizione: 'Hilft bei: Tag Fördern Sie Entspannung und Ruhe, Beruhigen Sie Spannungen. Nacht: 	Fördern Sie den Schlaf, verbringen Sie ruhige, friedliche und erholsame Nächte',
      descrPrezzo: 'euro',
      cash: 19.90,
      qty: 1
   },
   { img: "../../../assets/respirazionespraynasaleipertonico-min.jpg",
      nome: 'Bio Hypertonisches Nasenspray 15 ml',
      descrizione: 'Sofortige Wirkung: entstaut ab den ersten Minuten! Macht die Nase frei für besseres 	Atmen Entlastet gereizte Nasenschleimhäute Befeuchtet und reinigt verstopfte Nasenschleimhäute',
      descrPrezzo: 'euro',
      cash: 11.95,
      qty: 1
   },
   { img: "../../../assets/respirazionespraygola-min.jpg",
      nome: 'Atmendes Rachenspray 15 ml',
      descrizione: 'Ätherisches Öl der Minze: nützlich für die Funktionalität der Atemwege. Ätherisches 	Thymianöl: fördert das Wohlbefinden des Halses',
      descrPrezzo: 'euro',
      cash: 10.90,
      qty: 1
   },
   { img: "../../../assets/sforzomuscolareoliopermassaggiobiologicoarnica-gaultheria-min.jpg",
      nome: 'Muskelbelastung Bio-Massageöl Arnika - Gaultheria 100 ml',
      descrizione: 'Das Muscle Exertion Treatment Oil ist ideal für den Freizeit- oder Profisportler',
      descrPrezzo: 'euro',
      cash: 16.90,
      qty: 1
   },
   { img: "../../../assets/snellenteoliosecco-min.jpg",
      nome: 'Slimming trockenöl 100 ml',
      descrizione: 'Diese Behandlung hilft, den Orangenschaleneffekt zu reduzieren und das Erscheinungsbild 	der Cellulite zu verbessern. Seine nicht fettende Textur und spendet Feuchtigkeit, indem sie sehr leicht 	eindringt und keine Spuren hinterlässt',
      descrPrezzo: 'euro',
      cash: 24.90,
      qty: 1
   },
   { img: "../../../assets/respokessenzearomaticheperbagnodoccia-min.jpg",
      nome: 'Resp ok® aromatische Essenzen für Bad und Dusche 100 ml',
      descrizione: 'Er verströmt einen reinigenden Duft, der hilft: Komfort und Wohlbefinden in der frühen 	Erkältungszeit zu bringen',
      descrPrezzo: 'euro',
      cash: 13.90,
      qty: 1
   },
   { img: "../../../assets/rollerrotonditaribelli-min.jpg",
      nome: 'Aufmüpfigkeit Roller 75 ml',
      descrizione: 'Die dynamische Massage des Fettgewebes ist anerkannt, um das ästhetische 	Erscheinungsbild der Cellulite an aufmüpfigen und diätresistenten Stellen (Oberschenkel, Gesäß, Bauch...) zu verbessern. Die mechanische lipolevigierende Wirkung der Massagekugel des Rebel Roundness Roller',
      descrPrezzo: 'euro',
      cash: 16.90,
      qty: 1
   },
   { img: "../../../assets/sciroppodolcenottebambinibio-min.jpg",
      nome: 'Sirup Süße Nacht Kinder Bio 125 ml',
      descrizione: 'Kamille, Linde, Passionsblume zur Förderung von Schlaf und Entspannung und Mohn gegen 	lokale Spannungszustände',
      descrPrezzo: 'euro',
      cash: 14.20,
      qty: 1
   },
   { img: "../../../assets/respirazionesciroppotosse-min.jpg",
      nome: 'Hustensirup 125 ml',
      descrizione: 'Hilft zu: Befreit die oberen Atemwege durch Befeuchtung des Schleims Schützt die 	Schleimhaut dauerhaft, indem es Reizungen und die Häufigkeit des Hustens reduziert. Bietet einen milden Kühleffekt, der hilft, den durch die Reizung verursachten Schmerz zu unterstützen',
      descrPrezzo: 'euro',
      cash: 12.50,
      qty: 1
   },
   { img: "../../../assets/bellezzadellapellehomelifting.jpg",
      nome: '2 in 1 Behandlung Shampoo + Kamm 150 ml',
      descrizione: 'Puressentiel Anti-Läusekur Shampoo-Maske beseitigt Läuse, Larven und Nissen und macht 	das Haar in nur einem Schritt sauber',
      descrPrezzo: 'euro',
      cash: 18.50,
      qty: 1
   },
   { img: "../../../assets/snellentecofanettoanticellulite-min.jpg",
      nome: 'Set 2 Trockenöle 2x100ml + Saugnapf',
      descrizione: 'Diese Behandlung hilft, den Orangenhauteffekt zu reduzieren und das Erscheinungsbild der 	Cellulite zu verbessern',
      descrPrezzo: 'euro',
      cash: 49.50,
      qty: 1
   },
   { img: "../../../assets/sonniserenibalsamodamassaggiocalmantebimbo-min.jpg",
      nome: 'Sonni sereni beruhigender Massagebalsam für Baby 30 ml',
      descrizione: 'Seine Zusammensetzung auf Basis natürlicher Extrakte aus Bio-Kamille matricaria, Bio-	Lavendel vera, Bio-Orange und Bio-Majoran setzt beruhigende und entspannende aromatische Düfte frei, die die Erholung fördern. Dieser Massagebalsam ist ideal, um die Kleinen ab 6 Monaten zu beruhigen und auf die Ruhe vorzubereiten',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },
   { img: "../../../assets/sonniserenibalsamodamassaggiocalmantebimbo-min.jpg",
      nome: 'Sonni sereni beruhigender Massagebalsam für Baby 30 ml',
      descrizione: 'Seine Zusammensetzung auf Basis natürlicher Extrakte aus Bio-Kamille matricaria, Bio-	Lavendel vera, Bio-Orange und Bio-Majoran setzt beruhigende und entspannende aromatische Düfte frei, 	die die Erholung fördern. Dieser Massagebalsam ist ideal, um die Kleinen ab 6 Monaten zu beruhigen und 	auf die Ruhe vorzubereiten',
      descrPrezzo: 'euro',
      cash: 9.95,
      qty: 1
   },

  ];


  constructor(private bService: BasketService, private translation: TranslationService,
    public translate: TranslateService) { }

  //whichLang = this.translate.get(this.translate.currentLang);
  //whichLang: string = this.translation.lang;


  ngOnInit(): void {
    this.whichLang = this.translation.getLanguage();
    this.translation.langChanged
      .subscribe(
        (whichLang) => {
          this.whichLang = whichLang.pop();
        }
      );
    //console.log(this.whichLang[0]);
  }

  onAddToCart(nm, im, pr) {
    const alimName = nm;
    const alimPrezzo = pr;
    const alimeImg = im;
    const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
    this.bService.addAliment(newAliment);
    this.idx += 1;
  }

}
