<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
  <a class="navbar-brand" href="#">
    <!--<img src="../../assets/150543309_2521067248199245_9016197292398977635_n.jpg" width="30" height="30" class="d-inline-block align-top" alt="">-->
      DEE Natural
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#caccapupu" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleCollapsed()">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!--<div class="collapse navbar-collapse" id="caccapupu">-->
    <div id="caccapupu" [ngClass]="{'collapse':collapsed, 'navbar-collapse': true}">

    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"  routerLink="/" (click)="toggleCollapsed()">Home</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLinkActive="active"  routerLink="/Cantum" (click)="toggleCollapsed()">Shop</a>
      </li>
      <!--<li ngbDropdown class="dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" ngbDropdownToggle (click)="onEspandi()">
          Shop <span class="caret"></span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu">
          <li><a class="dropdown-item" routerLinkActive="active" routerLink="/Supplements" [translate]="'navbar.link2'"></a></li>
          <li><a class="dropdown-item" routerLinkActive="active" routerLink="/Perfums" [translate]="'navbar.link3'"></a></li>
          <li><a class="dropdown-item" routerLinkActive="active" routerLink="/Foods" [translate]="'navbar.link4'"></a></li>
          <li><a class="dropdown-item" routerLinkActive="active" routerLink="/Other" [translate]="'navbar.link5'"></a></li>
        </div>
      </li>-->

      <li ngbDropdown class="dropdown" id="language" [ngStyle]="{'margin-left': collapsed ? '390%' : '0%' }">
        <a class="nav-link dropdown-toggle" role="button" ngbDropdownToggle>
          Language <span class="caret"></span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu">
          <li (click)="useLanguage('en')"><img src="../../assets/american-flag-2144392_640-min.png" alt=""></li>
          <li (click)="useLanguage('de')"><img src="../../assets/germany-31017_640-min.png" alt=""></li>
          <li (click)="useLanguage('it')"><img src="../../assets/flag-28543_640-min.png" alt=""></li>
          <li (click)="useLanguage('fr')"><img src="../../assets/france-28463_640-min.png" alt=""></li>
        </div>
      </li>
    </ul>

  </div>
</nav>
