<app-head titolo="navbar.link4"></app-head>
<body>

  <!---->
  <div class="row">
    <!--<h1 [translate]="'navbar.link4'"></h1>-->
    <span class="spacer"></span>
    <app-basket-button *ngIf="idx > 0"></app-basket-button>
  </div>


  <!--<p>

    <button type="button" class="btn btn-outline-link btn-lg btn-block rounded-pill" (click)="isCollapsed1 = !isCollapsed1"
            [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample" id="cats">
          <p [translate]="'alimenti.oil'" id="titolo"></p>
          <i class="fas fa-chevron-down" [hidden]="!isCollapsed1"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed1"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed1">
    <app-olio (idxEvent)="receiveIdx($event)" [whichLang]="whichLang"></app-olio>
  </div>
  <hr>-->

  <p>

    <button type="button" class="btn btn-outline-link btn-lg btn-block rounded-pill" (click)="isCollapsed2 = !isCollapsed2"
            [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample" id="cats">
          <p [translate]="'alimenti.olives'" id="titolo"></p>
          <i class="fas fa-chevron-down" [hidden]="!isCollapsed2"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed2"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed2">
    <app-olive (idxEvent)="receiveIdx($event)" [whichLang]="whichLang"></app-olive>
  </div>
  <hr>

  <!--<p>

    <button type="button" class="btn btn-outline-link btn-lg btn-block rounded-pill" (click)="isCollapsed3 = !isCollapsed3"
            [attr.aria-expanded]="!isCollapsed3" aria-controls="collapseExample" id="cats">
          <p [translate]="'alimenti.pate'" id="titolo"></p>
          <i class="fas fa-chevron-down" [hidden]="!isCollapsed3"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed3"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed3">
    <app-pate (idxEvent)="receiveIdx($event)" [whichLang]="whichLang"></app-pate>
  </div>
  <hr>-->




<!--<div class="container-fluid" [ngSwitch]="whichLang">
  <div *ngSwitchDefault>
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of alimenti">
        <img src="{{ aliment.img }}" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.descrPrezzo, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngSwitchCase="'en'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of aliments">
        <img src="{{ aliment.img }}" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.descrPrezzo, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'de'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of alimenten">
        <img src="{{ aliment.img }}" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.descrPrezzo, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'it'">
    <div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of alimenti">
        <img src="{{ aliment.img }}" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.descrPrezzo, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'fr'">
    <h3>Va arriver bientôt</h3>
  </div>-->
    <!--<div class="row justify-content-center">
      <div class="card" *ngFor="let aliment of alimenten">
        <img src="{{ aliment.img }}" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">{{ aliment.nome }}</h5>
          <p class="card-text">{{ aliment.descrizione }}</p>
          <div class="card-footer">
            <small class="text-muted">{{ aliment.descrPrezzo }}: {{ aliment.cash }}</small>
            <hr>
            <app-cart-button (click)="onAddToCart(aliment.nome,aliment.descrPrezzo, aliment.cash)"></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </div>-->



<!--</div>-->
</body>
