import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-steril-ball',
  templateUrl: './steril-ball.component.html',
  styleUrls: ['./steril-ball.component.scss']
})
export class SterilBallComponent implements OnInit {
  idx: number = 1;
  @Output() idxEvent = new EventEmitter<number>();
  @Input() whichLang: string;

  altri = [
    { img: "../../../assets/fotosteeril.jpg",
     nome: 'NOOR Sterilball Sanificazione pallone da calcio',
     descrizione: 'Con batteria + logo personalizzabile',
     descrPrezzo: 'euro',
     cash: 6500.00,
     qty: 1
  },
  { img: "../../../assets/fotosteeril.jpg",
     nome: 'NOOR Sterilball Sanificazione pallone da calcio',
     descrizione: 'Con alimentazione a rete + logo personalizzabile',
     descrPrezzo: 'euro',
     cash: 5500.00,
     qty: 1
  },
 ];

 altren = [
  { img: "../../../assets/fotosteeril.jpg",
   nome: 'NOOR Sterilball Desinfektionsfussball',
   descrizione: 'Mit Batterie + anpassbarem Logo',
   descrPrezzo: 'euro',
   cash: 6500.00,
   qty: 1
},
{ img: "../../../assets/fotosteeril.jpg",
   nome: 'NOOR Sterilball Desinfektionsfussball',
   descrizione: 'Mit Steckernetzteil + individuell gestaltbarem Logo',
   descrPrezzo: 'euro',
   cash: 5500.00,
   qty: 1
},
 ];


 altrs = [
  { img: "../../../assets/fotosteeril.jpg",
   nome: 'NOOR Sterilball Sanitizing soccer ball',
   descrizione: 'With battery + customizable logo',
   descrPrezzo: 'euro',
   cash: 6500.00,
   qty: 1
},
{ img: "../../../assets/fotosteeril.jpg",
   nome: 'NOOR Sterilball Sanitizing soccer ball',
   descrizione: 'With mains power supply + customisable logo',
   descrPrezzo: 'euro',
   cash: 5500.00,
   qty: 1
},
 ];

 constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }

 ngOnInit(): void {
 }

 onAddToCart(nm, im, pr) {
   const alimName = nm;
   const alimPrezzo = pr;
   const alimeImg = im;
   const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
   this.bService.addAliment(newAliment);
   //this.idx += 1;
   this.idxEvent.emit(this.idx);
 }

}
