import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BasketService } from '../ordinare/Basket.service';
import { Magliette } from '../magliette.model';
import { TranslationService } from '../shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { inOutAnimation } from '../shared/my-animation';

@Component({
  selector: 'app-profumi-normali',
  templateUrl: './profumi-normali.component.html',
  styleUrls: ['./profumi-normali.component.scss']
})
export class ProfumiNormaliComponent implements OnInit {
  idx: number = 1;
  @Output() idxEvent = new EventEmitter<number>();
  @Input() whichLang: string;

  profumi = [
    { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'AFRODITE',
      descrizione: 'Dal gusto ORIENTALE E VANIGLIATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'AFRODITE',
      descrizione: 'Dal gusto ORIENTALE E VANIGLIATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ECATE',
      descrizione: 'Dal gusto FLOREALE CIPRIATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ECATE',
      descrizione: 'Dal gusto FLOREALE CIPRIATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'GEA',
      descrizione: 'Dal gusto FRUTTATO GOURMANDE per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/Pharma Bio Profumi.jpg",
      nome: 'GEA',
      descrizione: 'Dal gusto FRUTTATO GOURMANDE per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'PERSEFONE',
      descrizione: 'Dal gusto FLOREALE CIPRIATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'PERSEFONE',
      descrizione: 'Dal gusto FLOREALE CIPRIATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ARTEMIDE',
      descrizione: 'Dal gusto FRUTTATO FLOREALE per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ARTEMIDE',
      descrizione: 'Dal gusto FRUTTATO FLOREALE per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOS',
      descrizione: 'Dal gusto FLOREALE CIPRIATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOS',
      descrizione: 'Dal gusto FLOREALE CIPRIATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ILIZIA',
      descrizione: 'Dal gusto SPEZIATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ILIZIA',
      descrizione: 'Dal gusto SPEZIATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'SELENE',
      descrizione: 'Dal gusto FLOREALE AMBRATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'SELENE',
      descrizione: 'Dal gusto FLOREALE AMBRATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ATENA',
      descrizione: 'Dal gusto FLOREALE ORIENTALE per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ATENA',
      descrizione: 'Dal gusto FLOREALE ORIENTALE per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERA',
      descrizione: 'Dal gusto FRUTTATO SPEZIATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERA',
      descrizione: 'Dal gusto FRUTTATO SPEZIATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IRIS',
      descrizione: 'Dal gusto FRUTTATO LEGNOSO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IRIS',
      descrizione: 'Dal gusto FRUTTATO LEGNOSO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'TICHE',
      descrizione: 'Dal gusto FLOREALE AMBRATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'TICHE',
      descrizione: 'Dal gusto FLOREALE AMBRATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DEMETRA',
      descrizione: 'Dal gusto FRUTTATO CIPRIATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DEMETRA',
      descrizione: 'Dal gusto FRUTTATO CIPRIATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERIS',
      descrizione: 'Dal gusto FLOREALE ALDEIDICO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERIS',
      descrizione: 'Dal gusto FLOREALE ALDEIDICO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'MOIRE',
      descrizione: 'Dal gusto FLOREALE LEGONSO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'MOIRE',
      descrizione: 'Dal gusto FLOREALE LEGONSO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EBE',
      descrizione: 'Dal gusto FRUTTATO FLOREALE per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EBE',
      descrizione: 'Dal gusto FRUTTATO FLOREALE per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ESTIA',
      descrizione: 'Dal gusto FLOREALE AMBRATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ESTIA',
      descrizione: 'Dal gusto FLOREALE AMBRATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'NIKE',
      descrizione: 'Dal gusto FLOREALE FRUTTATO per femmine',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'NIKE',
      descrizione: 'Dal gusto FLOREALE FRUTTATO per femmine',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DIONISIO',
      descrizione: 'Dal gusto LEGONOSO NOTE MARINE per maschi',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DIONISIO',
      descrizione: 'Dal gusto LEGONOSO NOTE MARINE per maschi',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IPNO',
      descrizione: 'Dal gusto LEGNOSO NOTE MARINE per maschi',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IPNO',
      descrizione: 'Dal gusto LEGNOSO NOTE MARINE per maschi',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ELIO',
      descrizione: 'Dal gusto AGRUMATO AROMATICO per maschi',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ELIO',
      descrizione: 'Dal gusto AGRUMATO AROMATICO per maschi',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEFIRO',
      descrizione: 'Dal gusto AGRUMATO NOTE MARINE per maschi',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
 { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEFIRO',
      descrizione: 'Dal gusto AGRUMATO NOTE MARINE per maschi',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOLO',
      descrizione: 'Dal gusto LEGNOSO AGRUMATO per maschi',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOLO',
      descrizione: 'Dal gusto LEGNOSO AGRUMATO per maschi',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEUS',
      descrizione: 'Dal gusto AROMATICO FLOREALE per maschi',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEUS',
      descrizione: 'Dal gusto AROMATICO FLOREALE per maschi',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERACLE',
      descrizione: 'Dal gusto FRUTTATO SPEZIATO per maschi',
      descrPrezzo: 'Contenitore da 30 ml: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERACLE',
      descrizione: 'Dal gusto FRUTTATO SPEZIATO per maschi',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12(1)-min.jpeg",
      nome: 'PROFUMO PER AMBIENTI',
      descrizione: 'al gusto d’UVA ROSSA E RIBES',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12-min.jpeg",
      nome: 'PROFUMO PER AMBIENTI',
      descrizione: 'al gusto d’ARANCIA E CANNELLA',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(2)-min.jpeg",
      nome: 'PROFUMO PER AMBIENTI',
      descrizione: 'al gusto di BREZZA MARINA',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(1)-min.jpeg",
      nome: 'PROFUMO PER AMBIENTI',
      descrizione: 'al gusto di VANIGLIA',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13-min.jpeg",
      nome: 'PROFUMO PER AMBIENTI',
      descrizione: 'al gusto di AGRUMI E GELSOMINO',
      descrPrezzo: 'Contenitore da 100 ml: euro',
      cash: 9.90,
      qty: 1
   },
  ];



  profumen = [
    { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'AFRODITE',
      descrizione: 'ORIENTAL- UND VANILLA-Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'AFRODITE',
      descrizione: 'ORIENTAL- UND VANILLA-Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ECATE',
      descrizione: 'FLORAL CYPRIATE Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ECATE',
      descrizione: 'FLORAL CYPRIATE Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'GEA',
      descrizione: 'FRUITY GOURMAND Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'GEA',
      descrizione: 'FRUITY GOURMAND Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'PERSEFONE',
      descrizione: 'FLORAL CYPRIATE Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'PERSEFONE',
      descrizione: 'FLORAL CYPRIATE Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ARTEMIDE',
      descrizione: 'FRUITFUL FLORAL Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ARTEMIDE',
      descrizione: 'FRUITFUL FLORAL Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOS',
      descrizione: 'FLORAL CYPRIATE Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOS',
      descrizione: 'FLORAL CYPRIATE Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ILIZIA',
      descrizione: 'Scharfer Geschmack für Weibchen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ILIZIA',
      descrizione: 'Scharfer Geschmack für Weibchen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'SELENE',
      descrizione: 'FLORAL AMBER Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'SELENE',
      descrizione: 'FLORAL AMBER Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ATENA',
      descrizione: 'ORIENTAL FLORAL Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ATENA',
      descrizione: 'ORIENTAL FLORAL Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERA',
      descrizione: 'FRUITFUL SPICY Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERA',
      descrizione: 'FRUITFUL SPICY Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IRIS',
      descrizione: 'Holziger FRUIT-Geschmack für Weibchen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IRIS',
      descrizione: 'Holziger FRUIT-Geschmack für Weibchen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'TICHE',
      descrizione: 'FLORAL AMBER Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'TICHE',
      descrizione: 'FLORAL AMBER Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DEMETRA',
      descrizione: 'Mit FRUITY CYPRESS-Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DEMETRA',
      descrizione: 'Mit FRUITY CYPRESS-Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERIS',
      descrizione: 'FLORAL ALDEIC Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERIS',
      descrizione: 'FLORAL ALDEIC Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'MOIRE',
      descrizione: 'FLORAL LEGEND Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'MOIRE',
      descrizione: 'FLORAL LEGEND Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EBE',
      descrizione: 'FRUITFUL FLORAL Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EBE',
      descrizione: 'FRUITFUL FLORAL Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ESTIA',
      descrizione: 'FLORAL AMBER Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ESTIA',
      descrizione: 'FLORAL AMBER Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'NIKE',
      descrizione: 'FLORAL FRUITFUL Geschmack für Frauen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'NIKE',
      descrizione: 'FLORAL FRUITFUL Geschmack für Frauen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DIONISIO',
      descrizione: 'Mit einem LEGOROUS-Geschmack SEA NOTES für Männchen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DIONISIO',
      descrizione: 'Mit einem LEGOROUS-Geschmack SEA NOTES für Männchen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IPNO',
      descrizione: 'Holziger Geschmack Marinetöne für Männchen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IPNO',
      descrizione: 'Holziger Geschmack Marinetöne für Männchen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ELIO',
      descrizione: 'AROMATIC AGRUMATO Geschmack für Männchen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ELIO',
      descrizione: 'AROMATIC AGRUMATO Geschmack für Männchen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEFIRO',
      descrizione: 'Vom Geschmack her AGRUMATO MARINE NOTES für Männchen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
 { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEFIRO',
      descrizione: 'Vom Geschmack her AGRUMATO MARINE NOTES für Männchen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOLO',
      descrizione: 'Holziger AGRUMATO-Geschmack für Männchen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOLO',
      descrizione: 'Holziger AGRUMATO-Geschmack für Männchen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEUS',
      descrizione: 'AROMATIC FLORAL Geschmack für Männchen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEUS',
      descrizione: 'AROMATIC FLORAL Geschmack für Männchen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERACLE',
      descrizione: 'Fruchtig-würziger Geschmack für Männchen',
      descrPrezzo: '30 ml Behälter: Euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERACLE',
      descrizione: 'Fruchtig-würziger Geschmack für Männchen',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12(1)-min.jpeg",
      nome: 'RAUMDUFT',
      descrizione: 'RED GRAPE AND RIBES aromatisiert',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12-min.jpeg",
      nome: 'RAUMDUFT',
      descrizione: 'mit Orangen- und Zimtgeschmack',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(2)-min.jpeg",
      nome: 'RAUMDUFT',
      descrizione: 'mit dem Geschmack von BREZZA MARINA',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(1)-min.jpeg",
      nome: 'RAUMDUFT',
      descrizione: 'VANILLA aromatisiert',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13-min.jpeg",
      nome: 'RAUMDUFT',
      descrizione: 'aromatisiert mit KARROTEN UND JASMIN',
      descrPrezzo: '100 ml Behälter: Euro',
      cash: 9.90,
      qty: 1
   },
  ];

  profums = [
    { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'AFRODITE',
      descrizione: 'ORIENTAL AND VANILLA taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'AFRODITE',
      descrizione: 'ORIENTAL AND VANILLA taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ECATE',
      descrizione: 'FLORAL CIPRIATE taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ECATE',
      descrizione: 'FLORAL CIPRIATE taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'GEA',
      descrizione: 'FRUITY GOURMAND taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'GEA',
      descrizione: 'FRUITY GOURMAND taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'PERSEFONE',
      descrizione: 'FLORAL CIPRIATE taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'PERSEFONE',
      descrizione: 'FLORAL CIPRIATE taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ARTEMIDE',
      descrizione: 'FRUITFUL FLORAL taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ARTEMIDE',
      descrizione: 'FRUITFUL FLORAL taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOS',
      descrizione: 'FLORAL CIPRIATE taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOS',
      descrizione: 'FLORAL CIPRIATE taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ILIZIA',
      descrizione: 'Spicy taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ILIZIA',
      descrizione: 'Spicy taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'SELENE',
      descrizione: 'FLORAL AMBER taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'SELENE',
      descrizione: 'FLORAL AMBER taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ATENA',
      descrizione: 'ORIENTAL FLORAL taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ATENA',
      descrizione: 'ORIENTAL FLORAL taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERA',
      descrizione: 'FRUITFUL SPICY taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERA',
      descrizione: 'FRUITFUL SPICY taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IRIS',
      descrizione: 'Woody FRUIT taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IRIS',
      descrizione: 'Woody FRUIT taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'TICHE',
      descrizione: 'FLORAL AMBER taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'TICHE',
      descrizione: 'FLORAL AMBER taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DEMETRA',
      descrizione: 'With FRUITY CYPRESS taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DEMETRA',
      descrizione: 'With FRUITY CYPRESS taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERIS',
      descrizione: 'FLORAL ALDEIC taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERIS',
      descrizione: 'FLORAL ALDEIC taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'MOIRE',
      descrizione: 'FLORAL LEGEND taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'MOIRE',
      descrizione: 'FLORAL LEGEND taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EBE',
      descrizione: 'FRUITFUL FLORAL taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EBE',
      descrizione: 'FRUITFUL FLORAL taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ESTIA',
      descrizione: 'FLORAL AMBER taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ESTIA',
      descrizione: 'FLORAL AMBER taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'NIKE',
      descrizione: 'FLORAL FRUITFUL taste for females',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'NIKE',
      descrizione: 'FLORAL FRUITFUL taste for females',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DIONISIO',
      descrizione: 'With a LEGOROUS taste SEA NOTES for males',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'DIONISIO',
      descrizione: 'With a LEGOROUS taste SEA NOTES for males',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IPNO',
      descrizione: 'Woody taste Marine notes for males',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'IPNO',
      descrizione: 'Woody taste Marine notes for males',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ELIO',
      descrizione: 'AROMATIC AGRUMATO taste for males',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ELIO',
      descrizione: 'AROMATIC AGRUMATO taste for males',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEFIRO',
      descrizione: 'From the taste AGRUMATO MARINE NOTES for males',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
 { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEFIRO',
      descrizione: 'From the taste AGRUMATO MARINE NOTES for males',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOLO',
      descrizione: 'Woody AGRUMATO taste for males',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'EOLO',
      descrizione: 'Woody AGRUMATO taste for males',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEUS',
      descrizione: 'AROMATIC FLORAL taste for males',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ZEUS',
      descrizione: 'AROMATIC FLORAL taste for males',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERACLE',
      descrizione: 'Fruity spicy taste for males',
      descrPrezzo: '30 ml container: euro',
      cash: 6.90,
      qty: 1
   },
   { img: "../../../assets/PharmaBioProfumi.jpg",
      nome: 'ERACLE',
      descrizione: 'Fruity spicy taste for males',
      descrPrezzo: '100 ml container: euro',
      cash: 14.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12(1)-min.jpeg",
      nome: 'ROOM PERFUME',
      descrizione: 'RED GRAPE AND RIBES flavoured',
      descrPrezzo: '100 ml container: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.12-min.jpeg",
      nome: 'ROOM PERFUME',
      descrizione: 'orange and cinnamon flavoured',
      descrPrezzo: '100 ml container: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(2)-min.jpeg",
      nome: 'ROOM PERFUME',
      descrizione: 'with the taste of BREZZA MARINA',
      descrPrezzo: '100 ml container: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13(1)-min.jpeg",
      nome: 'ROOM PERFUME',
      descrizione: 'VANILLA flavoured',
      descrPrezzo: '100 ml container: euro',
      cash: 9.90,
      qty: 1
   },
   { img: "../../../assets/WhatsAppImage2020-11-24at09.03.13-min.jpeg",
      nome: 'ROOM PERFUME',
      descrizione: 'flavoured with CARROTS AND JASMINE',
      descrPrezzo: '100 ml container: euro',
      cash: 9.90,
      qty: 1
   },
  ];


  constructor(private bService: BasketService, private translation: TranslationService, public translate: TranslateService) { }

  ngOnInit() {
    this.idx = this.bService.getIdx();
  }

  ngDoCheck() {
    this.idx = this.bService.getIdx();
  }

  onAddToCart(nm, im, pr) {
    const alimName = nm;
    const alimPrezzo = pr;
    const alimeImg = im;
    const newAliment = new Magliette(alimName, 1, alimeImg, alimPrezzo, 'S');
    this.bService.addAliment(newAliment);
    //this.idx += 1;
    this.idxEvent.emit(this.idx);
    this.bService.setIdx(1);
  }

}
